import { Component, OnInit } from '@angular/core';
import { AlertService, UserService } from '../_services';
import { ActivatedRoute,Params, Router } from '@angular/router';
@Component({
  selector: 'app-activate',
  templateUrl: './activate.component.html',
  styleUrls: ['./activate.component.scss']
})
export class ActivateComponent implements OnInit {
 token:string;
  constructor(
    private alertService: AlertService,
    private userService: UserService,
    private route: ActivatedRoute,
    private router :Router

  ) { }

  ngOnInit() {

    this.route.params.subscribe((params: Params) => this.token = params['token']);

    this.userService.activate(this.token)
    .subscribe(
      data => {       
          this.alertService.success(data.message, true);    
          localStorage.removeItem('currentUser');
          delete data.message;
          localStorage.setItem('currentUser', JSON.stringify(data));   
         
          this.router.navigate(['/login']);
         
      },
      error => { 

          this.alertService.error("Your account verification link has expired. Please request a new account verification link in your profile screen.",true);
          let currentUser = JSON.parse(localStorage.getItem('currentUser'));
         if (currentUser) {
            this.router.navigate(['/home']);
         }else{
          this.router.navigate(['/login']);
         }
          
         
      });
  }

}
