import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute, Params, NavigationEnd } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { filter } from 'rxjs/operators';
import { AlertService, UserService } from '../_services';
import { environment } from '../../environments/environment';
import { interval } from 'rxjs';


declare var $: any;

@Component({
    selector: 'app-index',
    templateUrl: './index.component.html',
    styleUrls: ['./index.component.scss']
})
export class IndexComponent implements OnInit {
    errors = new Map();
    category: any;
    videos;
    speakers;
    testimonials;
    i = 1;
    url = environment.apiUrl;
    ctrl = new FormControl(null, Validators.required);
    slideConfig = {
        slidesToShow: 4,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 5000,  
    };
    slideConfig1 = {
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 5000,  
    };
    slideConfig2 = {
        slidesToShow: 2,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 5000,  
    };
    addSlide() { }
    removeSlide() { }
    afterChange(e) {
    }
    @ViewChild('slickModal') slickModal;
   

    constructor(
        private router: Router,
        private http: HttpClient,
        private formBuilder: FormBuilder,
        private route: ActivatedRoute,
        private alertService: AlertService

    ) {

        let currentUser = JSON.parse(localStorage.getItem('currentUser'));
        if (currentUser) {
            this.router.navigate(['/home']);
        }
      
    }

    ngOnInit() {

        let currentUser = JSON.parse(localStorage.getItem('currentUser'));
        if (currentUser) {
            this.router.navigate(['/home']);
        }

        this.http.get(`${environment.apiUrl}/user-category?client_secret=` + environment.clientSecret)
            .subscribe(
                data => {
                    this.category = data;
                  
                },
                error => {
                   
                }
            );

        this.http.get(`${environment.apiUrl}/testimonials?client_secret=` + environment.clientSecret)
            .subscribe(
                data => {
                    this.testimonials = data;
                  

                },
                error => {
                   
                }
            );

        this.http.get(`${environment.apiUrl}/videos/most-viewed?client_secret=` + environment.clientSecret)
            .subscribe(
                data => {
                    this.videos = data;
                
                    $.getScript('../../assets/main.js');
                    this.videos.forEach(element => {
                        element.url = "video/" + element.id;
                    });



                },
                error => {
                   
                }
            );

        this.http.get(`${environment.apiUrl}/home/videos?client_secret=` + environment.clientSecret)
            .subscribe(
                (data:any) => {
                    this.speakers = data.data;
                 
                  
                },
                error => {
                   
                }
            );
    }

    playvideo() {
        $.getScript('../../assets/pause.js');
        this.alertService.video("https://player.vimeo.com/video/80388471?autoplay=1&title=0&byline=0&portrait=0");
    }

    counter(i: number) {
        return new Array(i);
    }

    swipeleft(){
        $("#h-i-w-right").trigger('click');
    }
    swiperight(){
        $("#h-i-w-left").trigger('click');
    }
}

