import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../_services';
import { Router } from '@angular/router';
declare var $: any;
@Component({
  selector: 'app-admin-navbar',
  templateUrl: './admin-navbar.component.html',
  styleUrls: ['./admin-navbar.component.scss']
})
export class AdminNavbarComponent implements OnInit {
   id=1;
  constructor(private authenticationService: AuthenticationService,
    private router: Router
  ) { }

  ngOnInit() {
    if(this.router.url.includes('admin/users')){
      this.id=1;
    }
    if(this.router.url.includes('admin/categories')){
      this.id=2;
    }
    if(this.router.url.includes('admin/speakers')||this.router.url.includes('admin/speaker')){
      this.id=3;
    }
    if(this.router.url.includes('admin/videos')||this.router.url.includes('admin/comments')){
      this.id=4;
    }
    if(this.router.url.includes('admin/survey')){
      this.id=5;
    }
    if(this.router.url.includes('admin/password')){
      this.id=6;
    }
    if(this.router.url.includes('admin/webinars')){
      this.id=8;
    }
   
  }

  logout(){
    this.authenticationService.logout();
  }
  addClass(id){   
  }



}
