import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params ,Route } from '@angular/router';
import { FormBuilder, FormGroup, Validators, AbstractControl } from '@angular/forms';
import { AlertService, UserService } from '../_services';
import { environment } from '../../environments/environment';
import { ThrowStmt } from '@angular/compiler';
import { hasLifecycleHook } from '@angular/compiler/src/lifecycle_reflector';

function passwordConfirming(c: AbstractControl): any {
  if(!c.parent || !c) return;
  const pwd = c.parent.get('password');
  const cpwd= c.parent.get('confirm')

  if(!pwd || !cpwd) return ;
  if (pwd.value !== cpwd.value) {
      return { invalid: true };

}
}

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  resetPassword: FormGroup;
  loading = false;   
  submitted = false;
  token :string;
  errors = new Map();
  get cpwd() {
    return this.resetPassword.get('confirm');
   }

  constructor(
    private formBuilder: FormBuilder,
    private userService: UserService,
    private route: ActivatedRoute,
   private router: Router,  
    private alertService: AlertService
  ) { }

  ngOnInit() {

    this.route.params.subscribe((params: Params) => this.token = params['token']);

    this.resetPassword = this.formBuilder.group({     
      email: ['', [Validators.required,Validators.email]],
      password: ['', [Validators.required, Validators.minLength(6)]],
      confirm: ['', [Validators.required,passwordConfirming]],
      client_secret: environment.clientSecret 
           
  });
  }

  

  get f() { return this.resetPassword.controls; }

  onSubmit() {

    this.submitted = true;

    if (this.resetPassword.invalid) {
      return;
    }
    this.loading = true;  

    this.userService.resetPassword(this.f.email.value,this.f.password.value,this.token)
    .subscribe(
      data => {       
          this.alertService.success(data.message, true);       
          this.loading = false;  
          this.submitted=false;
          this.errors.clear();
          localStorage.removeItem('currentUser');
          delete data.message;
          localStorage.setItem('currentUser', JSON.stringify(data));
          this.router.navigate(['/home']);
      },
      error => {        
          this.errors=error;
          if(!this.errors.has('email')){
          this.alertService.error('The password reset link is no longer valid. Please request another password reset email');
          }
          this.loading = false; 
              
      }); 

  }

}
