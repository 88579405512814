import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import {FormsModule, FormBuilder, FormGroup, Validators, FormControl, NgForm } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import saveAs from 'file-saver';
import { AlertService } from '../_services';

interface MyWindow extends Window {
  myFunction(): void;
}

declare var window: MyWindow;

@Component({
  selector: 'app-test',
  templateUrl: './test.component.html',
  styleUrls: ['./test.component.scss']
})
export class TestComponent implements OnInit {

  test_end = false;
  test_success = false;
  form: FormGroup;
  test;
  id;
  questions = [];
  loaded = false;
  result;
  ans = new Map();
  certificate_url;
  counter = 0;
  correct_arr = [];
  correct;
  validation_failed=false;
  attempt=0;
  certificate_id;


    print_popup = false;
    title = "";
    price: "";
    paid = false;
    currentUser;
    pro;
    premium;
    webinar;

  constructor(
    private http: HttpClient,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private alertService: AlertService,

  ) { this.currentUser = JSON.parse(localStorage.getItem('currentUser')); }

  ngOnInit() {

    this.pro = this.currentUser.pro;
    this.premium = this.currentUser.plan_id==3;

    this.route.params.subscribe((params: Params) => this.id = params['id']);
    this.webinar = this.route.snapshot.queryParamMap.get("webinar");
     let tmp=this.webinar?'webinar-test':'test'

    this.http.get(`${environment.apiUrl}/${tmp}/questions?id=` + this.id)
      .subscribe(
        data => {
          this.test = data;
          this.title=data['title'];
          this.test.questions.forEach(element => {
            this.questions.push({ 'id': element.id });
          });
          this.form = this.formBuilder.group(
            this.questions.reduce((acc, curr) => ({ ...acc, [curr.id]: '' }), {})
          );
          this.loaded = true;
         
        },
        error => {
        
        }
      );
  }

  onSubmit() {
    
    if(this.attempt){
      return;
    }

    Object.keys(this.form.controls).forEach(key => {

      this.ans[key] = this.form.get(key).value;
    });
    let tmp=this.webinar?'webinar-test':'test'

    this.http.post(`${environment.apiUrl}/${tmp}/answers?id=` + this.id, { questions: this.ans })
      .subscribe(
        data => {
          this.attempt=1;
          this.result = data;
          this.test_end = true;
          this.correct = this.result.correct_questions;
          this.correct.forEach(element => {
            this.correct_arr.push(element.id);
          });

          if (this.result.score >= 80) {
            this.test_success = true;
            this.certificate_url = this.result.certificate_url;
            this.certificate_id=this.result.certificate_id;
            this.paid = this.result.paid;
            if(!this.webinar)
            this.title = this.result.video_title;
            this.price = this.result.price;
          } else {
            this.test_success = false;
          }
          this.validation_failed=false;
        },
        error => {
     
          this.validation_failed=true;
        }
      );
  }

  print() {
    window.open(this.certificate_url);
   
  }

    print_show() {
        this.print_popup = true;
    }
    hide_popup() {
        this.print_popup = false;
    }

    pay() {
        this.router.navigate(['/payment'],
        { queryParams:
            {
                type: 3,
                id: this.certificate_id,
                name: this.title,
                price: this.price
            } 
        });
    }

  save(){
    this.http.post(`${environment.apiUrl}/video/certificate/save/` + this.certificate_id, { })
    .subscribe(
      data => {
        this.alertService.success("Your certificate was saved.");
      },
      error => {
        this.alertService.error(error.message);
      }
    );
  }

  reload() {
    this.test_end = false;
    this.test_success = false;
    this.test='';
    this.questions = [];
    this.loaded = false;
    this.result='';
    this.ans = new Map();
    this.certificate_url='';
    this.counter = 0;
    this.correct_arr = [];
    this.correct='';
    this.attempt=0;

    let tmp=this.webinar?'webinar-test':'test'

    this.http.get(`${environment.apiUrl}/${tmp}/questions?id=` + this.id)
      .subscribe(
        data => {
          this.test = data;
          this.test.questions.forEach(element => {
            this.questions.push({ 'id': element.id });
          });
          this.form = this.formBuilder.group(
            this.questions.reduce((acc, curr) => ({ ...acc, [curr.id]: '' }), {})
          );
          this.loaded = true;
         
        },
        error => {
         
        }
      );
    this.router.navigateByUrl('/test/' + this.id);
  }




}
