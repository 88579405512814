import { Component, OnInit } from '@angular/core';
import { AlertService } from '../../_services';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import saveAs from 'file-saver';
@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit {

  users: any = null;
  last;
  end_pagination=true;
  users_data;
  page;
  search;

  constructor(
    private alertService: AlertService,
    private router: Router,
    private http: HttpClient,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {

    let currentUser = JSON.parse(localStorage.getItem('currentUser'));
    if (currentUser) {
        if(currentUser.category=="Admin"){
            //this.router.navigate(['/admin/users']);
        }else{
        this.router.navigate(['/home']);
        }
    }
    this.http.get(`${environment.apiUrl}/admin/users`)
    .subscribe(
        data => {
            this.users = data;
            this.page = this.users.meta.current_page;
            this.users_data = this.users.data;
            this.last = this.users.meta.last_page;
                      
            if (this.last == this.page) {
                this.end_pagination = false;
            }
        },
        error => {
        
        }
    );
  }
  next_page(){
    this.page = this.page + 1;
    let url;
    if(this.search){
        url=`${environment.apiUrl}/admin/users?name=`+this.search+`&page=`+this.page;
    }else{
        url=`${environment.apiUrl}/admin/users?page=`+this.page;
    }
    this.http.get(url)
    .subscribe(
        data => {
          this.users = data;
          this.page = this.users.meta.current_page;
          this.last = this.users.meta.last_page;
          this.users_data = this.users_data.concat(this.users.data);
         
          if (this.last == this.page) {
              this.end_pagination = false;
          }
        },
        error => {
          
        }
    );
  }

  download(link){
    window.open(link);
    
  }

  view(link){
    window.open(link);
  }

  onEnter(value){
      this.search=value;
   
    this.http.get(`${environment.apiUrl}/admin/users?name=`+value)
    .subscribe(
        data => {
            this.users = data;
            this.page = this.users.meta.current_page;
            this.users_data = this.users.data;
            this.last = this.users.meta.last_page;
                      
            if (this.last == this.page) {
                this.end_pagination = false;
            }else{
                this.end_pagination = true;
            }
        },
        error => {
            
        }
    );
  }
  
  blockUser(id){
      this.http.post(`${environment.apiUrl}/admin/user/block`, { id: id })
      .subscribe(
        (data: any) => {
          this.alertService.success(data.message);
        },
        error => {

        }
      );
  }
  
  setHtml(blocked) {
      if (blocked) {
          return "Unblock";
      } else {
          return "Block";
      }
  }

}
