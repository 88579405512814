import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { AlertService } from '../../_services';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-survey',
  templateUrl: './survey.component.html',
  styleUrls: ['./survey.component.scss']
})
export class SurveyComponent implements OnInit {
  question_id = 0;
  questions = [{ id: 0, value: "", invalid: '/', options: [{ id: 0, content: "", question_id: '' }, { id: 1, content: "", question_id: '' }] }];
  chars = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];
  form;
  api_questions = [];
  form_sumbmited = false;
  survey;
  loading = false;
  invalid_g = '';

  constructor(private router: Router,
    private http: HttpClient,
    private alertService: AlertService, ) { }

  ngOnInit() {
    this.questions = [];
    this.http.get(`${environment.apiUrl}/survey`)
      .subscribe(
        data => {
          this.survey = data;
          this.survey.forEach(element => {
            this.questions.push({ 'id': element.id, invalid: '/', value: element.content, options: element.options });
          });


        },
        error => {

        }
      );
  }
  addQuestion() {
    this.question_id++;
    this.questions.push({ id: this.question_id, value: "", invalid: '/', options: [{ id: 0, content: "", question_id: '' }, { id: 1, content: "", question_id: '' }] });
  }
  addAnswers(id) {
    const new_id = this.questions[id].options[this.questions[id].options.length - 1].id;
    this.questions[id].options.push({ id: new_id + 1, content: "", question_id: '' });
  }
  removeAnswers(q) {
    this.questions[q].options.pop();
  }

  validate_form() {
    let status = false;
    this.invalid_g = '';



    this.questions.forEach(element => {
      element.invalid = '';
      if (element.value != "") {
        if (element.options[0].content == "" || element.options[1].content == "") {
          element.invalid = 'Please add at least two options';
          status = true;
        }
      }
    });
    return status;
  }

  questions_prepare() {

    this.questions.forEach(element => {
      if (element.value != "") {
        let opt = [];
        element.options.forEach(element => {
          if (element.content != "") {
            opt.push(element.content);
          }
        });
        this.api_questions.push({
          content: element.value,
          options: opt
        });
      }
    });



  }
  removeQuestion(i) {
    this.questions.splice(i, 1);
  }
  onSubmit(form) {

    this.form_sumbmited = true;

    if (this.validate_form()) {
      return;
    }

    this.questions_prepare();
    this.loading = true;
    this.http.post(`${environment.apiUrl}/admin/survey/store`, {
      questions: this.api_questions
    })
      .subscribe(
        (data: any) => {
          this.alertService.success(data.message, true);
          this.router.navigateByUrl('/admin/users')
          this.loading = false;
        },
        error => {
          this.loading = false;
        }
      )
  }

}
