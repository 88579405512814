import { Component, OnInit, Output, EventEmitter, ErrorHandler } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpClient, HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService, AlertService } from '../../_services';
import { environment } from '../../../environments/environment';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';

import { User } from '../../_models';
import { NavbarComponent } from '../../navbar/navbar.component';
declare var $: any;

@Component({
  selector: 'app-billing-info',
  templateUrl: './billing-info.component.html',
  styleUrls: ['./billing-info.component.scss']
})
export class BillingInfoComponent implements OnInit {
    updateInfo: FormGroup;
    updateCard: FormGroup;
    currentUser;
    parentInput;
    submitted = false;
    loading = false;
    loading_card = false;
    errors = new Map();
    name;
    email;
    phone;
    company;
    address;
    city;
    country;
    state;
    zip;

    method;
    card_number;
    expiry;
    cvv;

    brand;
    funding;
    last4;
    expiry_date;

  constructor(
    private http: HttpClient,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private userService: UserService,
    private alertService: AlertService
) {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this.parentInput=this.currentUser;
 }

  ngOnInit() {
    this.updateInfo = this.formBuilder.group({
      name: ['', Validators.required],
      email: [''],
      phone: [''],
      company: [''],
      address: [''],
      city: [''],
      country: [''],
      state: [''],
      zip: ['']
    });

    this.updateCard = this.formBuilder.group({
      card_number: [''],
      expiry: [''],
      cvv: ['']
    });

    this.http.get(`${environment.apiUrl}/user/details`)
      .subscribe(
        (data: any) => {
          this.name = data.name;
          this.email = data.email;
          this.phone = data.phone_number;
          this.company = data.company;
          this.address = data.address;
          this.city = data.city;
          this.country = data.country;
          this.state = data.state;
          this.zip = data.zip;

        })

        this.http.get(`${environment.apiUrl}/user/card`)
      .subscribe(
        (data: any) => {
          this.brand = data.brand;
          this.funding = data.funding;
          this.last4 = data.last4;
          this.expiry_date = data.expiry;

        })

    this.updateInfo.get('name').setValue(this.currentUser.name);
    this.updateInfo.get('email').setValue(this.currentUser.email);
    this.updateInfo.get('phone').setValue(this.currentUser.phone_number);
    this.updateInfo.get('company').setValue(this.currentUser.company);
    this.updateInfo.get('address').setValue(this.currentUser.address);
    this.updateInfo.get('city').setValue(this.currentUser.city);
    this.updateInfo.get('country').setValue(this.currentUser.country);
    this.updateInfo.get('state').setValue(this.currentUser.state);
    this.updateInfo.get('zip').setValue(this.currentUser.zip);

    if(this.currentUser.stripe) {
        this.method = this.currentUser.card_brand;
        this.card_number = "**** **** **** " + this.currentUser.card_last4;
        this.expiry = "**/****";
        this.cvv = "***";
    } else {
        this.card_number = "Your card number";
        this.expiry = "MM/YYYY";
        this.cvv = "Your card cvv";
    }    

  }

    ngAfterViewInit() {
        if((document.getElementById('countrySelect') as any).value != "null") {
        document.getElementById('countrySelect').style.color = "black";
        }
        if((document.getElementById('stateSelect') as any).value != "null") {
            document.getElementById('stateSelect').style.color = "black";
        }
    }

    onKey(event: any) { // without type info

    var key = event.keyCode || event.charCode;

        if(key != 8) {
            if(event.target.value.length === 2){
                event.target.value += '/';
            }
    }
}

    get f() { return this.updateInfo.controls; }

    onUpdateInfo() {
    this.submitted = true;

    
    this.loading = true;

    this.http.post(`${environment.apiUrl}/user/update-billing`, {
      name: this.f.name.value,
      phone_number: this.f.phone.value,
      company: this.f.company.value,
      address: this.f.address.value,
      city: this.f.city.value,
      country: this.f.country.value,
      state: (document.getElementById("stateSelect") as any).value,
      zip: this.f.zip.value,
    })
      .subscribe(
        (data: any) => {
        this.currentUser.name = this.f.name.value;
        this.currentUser.phone_number = this.f.phone.value;
        this.currentUser.company = this.f.company.value;
        this.currentUser.address = this.f.address.value;
        this.currentUser.city = this.f.city.value;
        this.currentUser.country = this.f.country.value;
        this.currentUser.state = this.f.state.value;
        this.currentUser.zip = this.f.zip.value;

        localStorage.setItem('currentUser', JSON.stringify(this.currentUser));
        
          this.alertService.success(data.message, true);
          this.loading = false;
          this.errors = new Map();
        },
        error => {
          this.loading = false;
          this.errors = error;
          this.alertService.error(error.get('message'));
        }
      )

  }

    get fc() { return this.updateCard.controls; }

    onUpdateCard() {
    this.submitted = true;

    if (this.updateCard.invalid) {
      return;
    }
    this.loading_card = true;

    this.http.post(`${environment.apiUrl}/user/update-card`, {
      number: this.fc.card_number.value,
      expiry: this.fc.expiry.value,
      cvc: this.fc.cvv.value,
    })
      .subscribe(
        (data: any) => {
        this.currentUser.card_last4 = this.fc.card_number.value.substr(this.fc.card_number.value.length - 4);
        this.currentUser.stripe = true;

        localStorage.setItem('currentUser', JSON.stringify(this.currentUser));
        
          this.alertService.success(data.message, true);

          this.card_number = "**** **** **** " + this.currentUser.card_last4;
          this.expiry = "**/****";
          this.cvv = "***";

          this.errors = new Map();

          this.updateCard = this.formBuilder.group({
            method: [this.funding, Validators.required],
            card_number: [''],
            expiry: [''],
            cvv: ['']
          });

          this.loading_card = false;
        },
        error => {
          this.loading_card = false;
          this.errors = error;
          this.alertService.error(error.get('message'));

          
          
        }
      ) 

  }
      
  

    selectChangeHandler (event: any) {    
        var selected = event.target;
        if(selected.value != "null") {
            selected.style.color = "black";
        }       
        else {
            selected.style.color = "#9B9B9B";
        }
        
        if(selected.id == "countrySelect") {
            if(selected.value == "United States") {
                (document.getElementById("stateSelect") as any).disabled = false;
            } else {
                (document.getElementById("stateSelect") as any).value = "null";
                (document.getElementById("stateSelect") as any).disabled = true;                
                document.getElementById("stateSelect").style.color = "#9B9B9B";
            }
        }  
    }

}
