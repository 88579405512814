import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { AlertService } from '../../../_services';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.scss']
})
export class EditComponent implements OnInit {

  category;
  roles;
  speakers;
  v_category = new Map();
  v_category_text = "";
  v_roles = new Map();
  v_roles_text = "";
  v_speakers = new Map();
  v_speakers_text = "";
  v_applicable=[];
  v_applicable_text="Choose User Package";
  question_id = 0;
  questions = [{ id: 0, value: "", correct: "", invalid: '', options: [{ id: 0, content: "", question_id: '' }, { id: 1, content: "", question_id: '' }] }];
  chars = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];
  form;
  video_name = "";
  video_url = "";
  video_desc = "";
  home;
  api_category = [];
  api_roles = [];
  api_speaker = "";
  api_questions = [];
  views;
  date_available;
  id;
  alldata;
  category_names = [];
  roles_names = [];
  q;
  role_id;
  speaker_id;
  form_sumbmited;
  invalid_link;
  errors = new Map();
  loading = false;

  constructor(
    private router: Router,
    private http: HttpClient,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private alertService: AlertService
  ) { }

  ngOnInit() {
    this.form = this.formBuilder.group({});
    this.route.params.subscribe((params: Params) => this.id = params['id']);

    this.http.get(`${environment.apiUrl}/video/categories`)
      .subscribe(
        data => {
          this.category = data;
        }
      );
    this.http.get(`${environment.apiUrl}/admin/video/create`)
      .subscribe(
        data => {
          let d: any = data;
          this.speakers = d.speakers;
          this.roles = d.user_types;


          this.http.get(`${environment.apiUrl}/admin/video/edit/` + this.id)
            .subscribe(
              (data: any) => {
                this.alldata = data;
                this.video_name = data.title;
                this.video_url = data.link;
                this.video_desc = data.description;
                this.home = data.homepage_show;
                this.date_available=data.date_available;
                this.views = data.views;
                this.v_applicable=Object.keys(data.plans);
                this.v_applicable=this.v_applicable.map(x=>+x)

                this.v_applicable_text=""
                if(this.v_applicable.length>0){
                  if(this.v_applicable.indexOf(1)>-1){
                    this.v_applicable_text="BASIC, "
                  }
                  if(this.v_applicable.indexOf(2)>-1){
                    this.v_applicable_text+="PRO, "
                  }
                  if(this.v_applicable.indexOf(3)>-1){
                    this.v_applicable_text+="PREMIUM"
                  }
                }else{
                  this.v_applicable_text="Choose User Package"
                }




                this.speakers.forEach(element => {
                  if (element.id == data.speaker) {
                    this.speaker_id = element.id;
                    this.api_speaker=element.id;
                    this.v_speakers_text=element.name;
                  }
                });   

                this.questions = [];
                let i = 0;
                let j = 0;
                let corect;

                data.questions.forEach(element => {
                  j = 0;

                  element.options.forEach(element2 => {
                    if (element2.id == element.correct_option_id) {
                      corect = j;
                    }
                    j++;
                  });

                  this.questions.push({
                    id: i++,
                    value: element.content,
                    correct: corect,
                    invalid: '',
                    options: element.options
                  });
                });
                this.question_id = this.questions.length;

                for (let key in data.categories) {
                  this.v_category_text += data.categories[key] + ", ";
                  this.api_category.push(key);
                  this.category_names.push(data.categories[key]);
                  this.v_category.set(data.categories[key], true)
                }
                for (let key in data.user_types) {
                  this.v_roles_text += data.user_types[key] + ", ";
                  this.api_roles.push(key);
                  this.roles_names.push(data.user_types[key]);
                  this.v_roles.set(data.user_types[key], true);
                }

                   
              }
            );
        }
      );


  }
  applicableChange(id){
    const index = this.v_applicable.indexOf(id);
    if (index > -1) {
      this.v_applicable.splice(index, 1);
    } else {
      this.v_applicable.push(id);
    }
this.v_applicable_text=""
if(this.v_applicable.length>0){
  if(this.v_applicable.indexOf(1)>-1){
    this.v_applicable_text="BASIC, "
  }
  if(this.v_applicable.indexOf(2)>-1){
    this.v_applicable_text+="PRO, "
  }
  if(this.v_applicable.indexOf(3)>-1){
    this.v_applicable_text+="PREMIUM"
  }
}else{
  this.v_applicable_text="Choose User Package"
}

    console.log(this.v_applicable)
  }


  onSubmit(form) {

    this.form_sumbmited = true;
    if (this.validate_form()) {
      return;
    }
    this.questions_prepare();
    this.loading = true;
    this.http.post(`${environment.apiUrl}/admin/video/store/` + this.id, {
      name: this.video_name,
      link: this.video_url,
      description: this.video_desc,
      categories: this.api_category,
      user_types: this.api_roles,
      speaker: this.speaker_id,
      date_available:this.date_available,
      views: this.views,
      plans:this.v_applicable,
      homepage_show: this.home ? 1 : 0,
      questions: this.api_questions
    })
      .subscribe(
        (data: any) => {
          this.alertService.success(data.message, true);
          this.router.navigateByUrl('/admin/videos')
          this.loading = false;
        },
        error => {
          this.loading = false;
          this.errors = error;
        }
      )
  }
  CategoryChange(values: any, type, id) {
    this.v_category.set(type, values.currentTarget.checked)

    if (this.api_category.includes(id.toString())) {
      this.api_category.splice(this.api_category.indexOf(id.toString()), 1);
    } else {
      this.api_category.push(id.toString());
    }


    this.v_category_text = "";
    this.v_category.forEach((value: boolean, key: string) => {

      if (value) {
        this.v_category_text += key + ", ";
      }
    });

  }

  RoleChange(values: any, type, id) {
    this.v_roles.set(type, values.currentTarget.checked)

    if (this.api_roles.includes(id.toString())) {
      this.api_roles.splice(this.api_roles.indexOf(id.toString()), 1);
    } else {
      this.api_roles.push(id.toString());
    }

    this.v_roles_text = "";
    this.v_roles.forEach((value: boolean, key: string) => {

      if (value) {
        this.v_roles_text += key + ", ";
      }
    });

  }

  SpeakerChange(values: any, type, id) {
    this.api_speaker = id;
    this.speaker_id=id;
    console.log( this.api_speaker);
    this.v_speakers_text = type;    
  }

  questions_prepare() {

    this.questions.forEach(element => {
      if (element.value) {
        let opt = [];
        element.options.forEach(element => {
          if (element.content != "") {
            opt.push(element.content);
          }
        });
        this.api_questions.push({
          content: element.value,
          correct: element.correct,
          options: opt
        });
      }
    });

  }

  corect(q_id, a_id) {
    this.questions[q_id].correct = a_id;
  }

  validate_form() {
    let status = false;

    if (this.video_name == "" || this.video_url == "" || this.video_desc == "") {
      status = true;
    }
    if (this.api_category.length == 0 || this.api_roles.length==0 || this.api_speaker == "") {
      status = true;
    }
    if (!((parseFloat(this.views) == parseInt(this.views)) && !isNaN(this.views))) {
      status = true;
    }
    if (!this.video_url.startsWith('https://player.vimeo.com/video/')) {
      status = true;
      this.invalid_link = "Video URL must be in the following format: https://player.vimeo.com/video/123456"
    } else {
      this.invalid_link = '';
    }


    this.questions.forEach(element => {
      element.invalid = '';
      if (element.value != "") {
        if (element.correct === "" || element.correct == "/" || !element.options[element.correct]) {
          element.invalid = 'Please choose correct answer';
          status = true
        }
        if (element.options[0].content == "" || element.options[1].content == "") {
          element.invalid = 'Please add at least two options';
          status = true;
        }
      }
    });
    return status;
  }

  removeAnswers(q) {
    this.questions[q].options.pop();
  }
  removeQuestion(i){
    this.questions.splice(i, 1);
  }

  isNotInteger() {

    if (this.views == '') {
      return false;
    }

    if (!((parseFloat(this.views) == parseInt(this.views)) && !isNaN(this.views))) {
      return true;
    }
    return false;
  }

  addQuestion() {
    this.question_id++;
    this.questions.push({ id: this.question_id, value: "", correct: "/", invalid: '', options: [{ id: 0, content: "", question_id: '' }, { id: 1, content: "", question_id: '' }] });
  }
  addAnswers(id) {
    const new_id = this.questions[id].options[this.questions[id].options.length - 1].id;
    this.questions[id].options.push({ id: new_id + 1, content: "", question_id: '' });
  }

  validateUrl() {
    if (!this.video_url.startsWith('https://player.vimeo.com/video/')) {
      this.invalid_link = "Video URL must be in the following format: https://player.vimeo.com/video/123456"
    } else {
      this.invalid_link = '';
    }
  }
}
