import {
  Component, OnInit, ViewChild,
  TemplateRef,
} from '@angular/core';
import {
  CalendarEvent,
  CalendarEventAction,
  CalendarEventTimesChangedEvent,
  CalendarView
} from 'angular-calendar';
import {
  startOfDay,
  endOfDay,
  subDays,
  addDays,
  endOfMonth,
  isSameDay,
  isSameMonth,
  addHours
} from 'date-fns';
import { Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { DatePipe } from '@angular/common';
import { AlertService } from '../_services';
import { ActivatedRoute, Router } from '@angular/router';

const colors: any = {
  red: {
    primary: '#E94559',
    secondary: '#F7F7F7'
  }
};

@Component({
  selector: 'app-webinars',
  templateUrl: './webinars.component.html',
  styleUrls: ['./webinars.component.scss']
})
export class WebinarsComponent implements OnInit {

  event = [];
  free = [];
  pro = [];
  paid = [];
  premium_data = [];
  event_list;
  premium;

  last_free;
  end_pagination_free = false;
  page_free = 1;

  last_pro;
  end_pagination_pro = false;
  page_pro = 1;

  last_paid;
  end_pagination_paid = false;
  page_paid = 1;

  last_premium;
  end_pagination_premium = false;
  page_premium = 1;

  type_free = 1;
  type_pro = 2;
  type_paid = 3;
  type_premium = 4;

  currentUser;
  pro_user;

  popup_type;
  pay;
  registerPrice = '';
  plan_id;

  display_msg = 0;

  refer_src = "../../assets/img/iconReferAFriend.svg";
  pay_src = "../../assets/img/iconPayMonthly.svg";
  divItems;
  active_item = 0;
  upgrade_popup = false;

  ready = false;
  month_text = "This Month";
  popup = false;
  registerName = '';
  registerTime = '';
  registerId = '';
  progres = false;
  filter_text='All Webinars';
  selected_cat=0;

  pipe = new DatePipe('en-US');

  @ViewChild('modalContent')
  modalContent: TemplateRef<any>;

  view: CalendarView = CalendarView.Month;

  CalendarView = CalendarView;

  viewDate: Date = new Date();

  modalData: {
    action: string;
    event: CalendarEvent;
  };

  month_year = this.pipe.transform(this.viewDate, 'yyyy-MM');

  actions: CalendarEventAction[] = [
    {
      label: '<i class="fa fa-fw fa-pencil"></i>',
      onClick: ({ event }: { event: CalendarEvent }): void => {
        this.handleEvent('Edited', event);
      }
    },
    {
      label: '<i class="fa fa-fw fa-times"></i>',
      onClick: ({ event }: { event: CalendarEvent }): void => {
        this.events = this.events.filter(iEvent => iEvent !== event);
        this.handleEvent('Deleted', event);
      }
    }
  ];

  refresh: Subject<any> = new Subject();

  events: CalendarEvent[] = [];

  activeDayIsOpen: boolean = true;
  constructor(private http: HttpClient,
    private alertService: AlertService,
    private route: ActivatedRoute,
    private router: Router,
  ) {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
  }

  ngOnInit() {
    this.divItems = document.getElementsByClassName("popup_rectangle");

    this.loadDataFree();
    this.loadDataPro();
    this.loadDataPaid();
    this.loadDataPremium();

    this.pro_user = this.currentUser.pro;
    this.plan_id = this.currentUser.plan_id;
    this.premium = this.plan_id == 3;


    this.http.get(`${environment.apiUrl}/webinar/dates`).subscribe(
      data => {
        this.event_list = data;

        this.event_list.forEach(element => {
          this.events.push({
            start: new Date(element),
            title: 'Webinars Available',
            color: colors.red,
            actions: this.actions
          });
        });

        this.ready = true;
      }
    )
  }
  reloadWebinars(f = null, text) {

    this.selected_cat=f,
    this.filter_text=text

  }

  loadDataFree(page_free = 1) {
    this.http.get(`${environment.apiUrl}/webinars?page=` + page_free + `&month_year=` + this.month_year + `&type=` + this.type_free)
      .subscribe(
        (data: any) => {
          if (page_free != 1) {
            this.free = this.free.concat(data.data);
          } else {
            this.free = data.data;
          }
          this.free.forEach(element => {
            element.show_more = true;
          });
          this.page_free = data.meta.current_page;
          this.last_free = data.meta.last_page;
          this.month_text = data.month;
          if (this.last_free == this.page_free) {
            this.end_pagination_free = false;
          } else {
            this.end_pagination_free = true;
          }
        },
        error => {

        }
      );
  }

  loadDataPro(page_pro = 1) {
    this.http.get(`${environment.apiUrl}/webinars?page=` + page_pro + `&month_year=` + this.month_year + `&type=` + this.type_pro)
      .subscribe(
        (data: any) => {
          if (page_pro != 1) {
            this.pro = this.pro.concat(data.data);
          } else {
            this.pro = data.data;
          }
          this.pro.forEach(element => {
            element.show_more = true;
          });
          this.page_pro = data.meta.current_page;
          this.last_pro = data.meta.last_page;
          this.month_text = data.month;
          if (this.last_pro == this.page_pro) {
            this.end_pagination_pro = false;
          } else {
            this.end_pagination_pro = true;
          }
        },
        error => {

        }
      );
  }

  loadDataPaid(page_paid = 1) {
    this.http.get(`${environment.apiUrl}/webinars?page=` + page_paid + `&month_year=` + this.month_year + `&type=` + this.type_paid)
      .subscribe(
        (data: any) => {
          if (page_paid != 1) {
            this.paid = this.paid.concat(data.data);
          } else {
            this.paid = data.data;
          }
          this.paid.forEach(element => {
            element.show_more = true;
          });
          this.page_paid = data.meta.current_page;
          this.last_paid = data.meta.last_page;
          this.month_text = data.month;
          if (this.last_paid == this.page_paid) {
            this.end_pagination_paid = false;
          } else {
            this.end_pagination_paid = true;
          }
        },
        error => {

        }
      );
  }

  loadDataPremium(page_premium = 1) {
    this.http.get(`${environment.apiUrl}/webinars?page=` + page_premium + `&month_year=` + this.month_year + `&type=` + this.type_premium)
      .subscribe(
        (data: any) => {
          if (page_premium != 1) {
            this.premium_data = this.premium_data.concat(data.data);
          } else {
            this.premium_data = data.data;
          }
          this.premium_data.forEach(element => {
            element.show_more = true;
          });
          this.page_premium = data.meta.current_page;
          this.last_premium = data.meta.last_page;
          this.month_text = data.month;
          if (this.last_premium == this.page_premium) {
            this.end_pagination_premium = false;
          } else {
            this.end_pagination_premium = true;
          }
        },
        error => {

        }
      );
  }



  nextPageFree() {
    this.loadDataFree(this.page_free + 1);
  }

  nextPagePro() {
    this.loadDataPro(this.page_pro + 1);
  }

  nextPagePaid() {
    this.loadDataPaid(this.page_paid + 1);
  }

  nextPagePremium() {
    this.loadDataPremium(this.page_premium + 1);
  }

  changeMonth() {
    this.month_year = this.pipe.transform(this.viewDate, 'yyyy-MM')

    this.loadDataFree();
    this.loadDataPro();
    this.loadDataPaid();
    this.loadDataPremium();
  }

  register(id, name, time, pay, price, registered) {
    if (!registered) {
      this.registerId = id;
      this.registerName = name;
      this.registerTime = time;
      this.pay = pay;
      this.popup_type = (pay == false || this.premium) ? 1 : 2;
      this.registerPrice = price;
      this.popup = true;
    }
  }

  upgrade_pro() {
    this.router.navigate(['/payment'], { queryParams: { type: 1 } });
  }

  pay_webinar() {
    this.router.navigate(['/payment'],
      {
        queryParams:
        {
          type: 2,
          id: this.registerId,
          name: this.registerName,
          time: this.registerTime,
          price: this.registerPrice
        }
      });
  }

  save_registration() {
    this.progres = true;
    this.http.post<any>(`${environment.apiUrl}/webinar/register`, { id: this.registerId }).subscribe(
      data => {
        this.popup = false;

        this.loadDataFree();
        this.loadDataPro();
        this.loadDataPaid();

        this.alertService.success(data.message);
        this.progres = false;
      },
      error => {
        this.progres = false;
      })


  }
  hide_popup() {
    this.popup = false;
  }

  dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }): void {
    if (isSameMonth(date, this.viewDate)) {
      this.viewDate = date;
      if (
        (isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) ||
        events.length === 0
      ) {
        this.activeDayIsOpen = false;
      } else {
        this.activeDayIsOpen = true;
      }
    }
  }

  handleEvent(action: string, event: CalendarEvent): void {
    this.modalData = { event, action };
  }

  diplayMsg(i) {
    if (this.display_msg == i) {
      this.display_msg = 0;
    } else {
      this.display_msg = i;
    }

  }

  upgradePopup() {
    this.upgrade_popup = true;
  }

  hide_popup_upgrade() {
    this.upgrade_popup = false;

    this.refer_src = "../../assets/img/iconReferAFriend.svg";
    this.pay_src = "../../assets/img/iconPayMonthly.svg";
    this.active_item = 0;
  }

  go_to_upgrade() {
    this.router.navigate(['/payment'], { queryParams: { type: 1 } });
  }

  selected(item, active_item) {
    this.active_item = active_item;
    this.clear();
    item.style.backgroundColor = '#DC5D63';
    if (active_item == 1) {
      this.pay_src = "../../assets/img/iconPayMonthlySelected.svg";
      this.refer_src = "../../assets/img/iconReferAFriend.svg";
    } else {
      this.refer_src = "../../assets/img/iconReferAFriendSelected.svg";
      this.pay_src = "../../assets/img/iconPayMonthly.svg";
    }
  }
  clear() {
    for (var i = 0; i < this.divItems.length; i++) {
      var item = this.divItems[i];
      item.style.backgroundColor = 'white';
    }
  }


}
