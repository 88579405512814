import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AlertService, UserService } from '../_services';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-reset',
  templateUrl: './reset.component.html',
  styleUrls: ['./reset.component.scss']
})
export class ResetComponent implements OnInit {

  resetForm: FormGroup;
  loading = false;
  submitted = false;
  errors = new Map();
  returnUrl: string;
  constructor(
    private formBuilder: FormBuilder,
    private userService: UserService,
    private route: ActivatedRoute,
    private router: Router,
    private alertService: AlertService
  ) { }

  ngOnInit() {
    this.resetForm = this.formBuilder.group({
      email: ['', [Validators.required , Validators.email]]
    });
  }

  get f() { return this.resetForm.controls; }

  onSubmit() {
    this.submitted = true;
    this.errors.clear();
    if (this.resetForm.invalid) {
      return;
    }
    this.loading = true;  

    this.userService.reset(this.f.email.value)
    .subscribe(
      data => {       
          this.alertService.success(data.message, true);       
          this.loading = false;  
      },
      error => {        
          this.errors=error;
          if(this.errors.has('email')){
            this.errors.set('email','There is no user registered with this email address');
            this.alertService.error('There is no user registered with this email address');
            }
          this.loading = false; 
             
      });        

  }
}