import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';

import { Router } from '@angular/router';
import { AlertService } from '../../_services';

@Component({
  selector: 'app-webinars',
  templateUrl: './webinars.component.html',
  styleUrls: ['./webinars.component.scss']
})
export class WebinarsComponent implements OnInit {

  constructor(private http: HttpClient, private alertService: AlertService, private router: Router ) { }
  webinars = [];
  last;
  end_pagination = false;
  page = 1;
  upcoming = 1;
  webinars_type = "Upcoming Webinars";
  delete_id;
  confirm_delete = false;
  stop_id="";
  confirm_stoping=false;
  action_type=false;

  ngOnInit() {
    this.loadData();
  }

  loadData(page = 1) {

    this.http.get(`${environment.apiUrl}/admin/webinars?page=` + page + `&upcoming=` + this.upcoming)
      .subscribe(
        (data: any) => {
          if (page != 1) {
            this.webinars = this.webinars.concat(data.data);
          } else {
            this.webinars = data.data;
          }
          this.webinars.forEach(element => {
            element.show_more=true;
        });
          this.page = data.meta.current_page;
          this.last = data.meta.last_page;
          if (this.last == this.page) {
            this.end_pagination = false;
          } else {
            this.end_pagination = true;
          }
        },
        error => {

        }
      );
  }

  type(type, text) {
    this.upcoming = type;
    this.webinars_type = text;
    this.loadData();

  }

  confirm(id) {
    this.delete_id = id;
    this.confirm_delete = true;
  }
  hide_popup_delete() {
    this.confirm_delete = false;
  }
  hide_popup_stop() {
    this.confirm_stoping = false;
  }
  delete() {
    this.http.post(`${environment.apiUrl}/admin/webinar/delete/` + this.delete_id, {})
      .subscribe(
        (data: any) => {
          this.alertService.success(data.message);
          this.loadData();
        },
        error => {
          this.alertService.success(error.get('message'));
        }
      )
    this.confirm_delete = false;
  }
  next_page() {
    this.loadData(this.page + 1);
  }

  list(id,name){
    this.router.navigateByUrl('/admin/webinars/list/'+id+'?name='+name);
  }
  comments(id,name){
    this.router.navigateByUrl('/admin/webinars/comment/'+id+'?name='+name);
  }
  
  create(){
    this.router.navigateByUrl('/admin/webinars/create');
  }
  edit(id){
    this.router.navigateByUrl('/admin/webinars/'+id);
  }
  stop(){
    this.http.post(`${environment.apiUrl}/admin/webinar/stop/` + this.stop_id, {})
    .subscribe(
      (data: any) => {
        this.alertService.success(data.message);
        this.loadData();
      },
      error => {
        this.alertService.success(error.get('message'));
      }
    )
    this.confirm_stoping=false;
  }
  confirm_stop(id,type){
    this.stop_id=id;
    this.confirm_stoping=true;
    this.action_type=type;
  }

}
