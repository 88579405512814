import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { UserService, AlertService } from '../../../_services';
import { environment } from '../../../../environments/environment';

declare var $: any;
@Component({
  selector: 'app-edit-speaker',
  templateUrl: './edit-speaker.component.html',
  styleUrls: ['./edit-speaker.component.scss']
})
export class EditSpeakerComponent implements OnInit {
  speaker;
  currentUser;
  add;
  submitted;
  loading;
  errors;
  fd = new FormData();
  file: File;
  id;
  url;

  constructor(
    private http: HttpClient,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private userService: UserService,
    private alertService: AlertService
  ) { }

  ngOnInit() {
    this.add = this.formBuilder.group({
      name: ['', Validators.required],
      info: ['', ],
      img: ['', ],     
    });

    this.route.params.subscribe((params: Params) => this.id = params['id']);

     this.http.get(`${environment.apiUrl}/admin/speaker/`+this.id)
     .subscribe(
       (data : any)=>{
         this.speaker=data;
         this.url=data.avatar;
         this.f.name.setValue(data.name)
         this.f.info.setValue(data.description)
       }
     );

  }

  get f() { return this.add.controls; }

  trigerImageUpload() {
    $("#imageupload:hidden").trigger('click');
  }
  imageChanged(event) {      
    if(event.target.files[0].type=="image/jpeg" || event.target.files[0].type=="image/jpg" || event.target.files[0].type=="image/png" ){
      this.file = event.target.files[0];   
      this.readUrl(event);
      }

  }

  onSubmit(event) {
    this.submitted = true;

    if (this.add.invalid) {
      return;
    }
    this.loading = true; 

    this.fd.append('name', this.f.name.value);
    if(this.f.info.value){
    this.fd.append('description', this.f.info.value);
    }
    if(this.file){
     this.fd.append('avatar', this.file, this.file.name);
    }


   
    this.http.post(`${environment.apiUrl}/admin/speaker/store/`+this.id,this.fd)
      .subscribe(
        (data:any) => {
            this.alertService.success(data.message,true);
            this.router.navigateByUrl('/admin/speakers');
        },
        error => {
        
        });

  }

  readUrl(event:any) {
    if (event.target.files && event.target.files[0]) {
      var reader = new FileReader();
  
      reader.onload = (event: ProgressEvent) => {
        this.url = (<FileReader>event.target).result;
      }
  
      reader.readAsDataURL(event.target.files[0]);
    }
  }

}
