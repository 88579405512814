﻿import { Component, OnInit, HostListener } from '@angular/core';
import { User } from '../_models';
import { UserService, AuthenticationService, AlertService } from '../_services';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { AnimateInModule } from 'ngx-animate-in';
import { trigger, style, transition, animate, keyframes, query, stagger } from '@angular/animations';


@Component({
    templateUrl: 'home.component.html',
    animations: [

        trigger('listAnimation', [
            transition('* => *', [

                query(':enter', style({ opacity: 0 }), { optional: true }),

                query(':enter', stagger('300ms', [
                    animate('0.8s ease-in', keyframes([
                        style({ opacity: 0, transform: 'translateY(-20px)', offset: 0 }),
                        style({ opacity: .5, transform: 'translateY(20px)', offset: 0.4 }),
                        style({ opacity: 1, transform: 'translateY(0)', offset: 0.8 }),
                    ]))]), { optional: true })
            ])
        ])

    ]
})
export class HomeComponent implements OnInit {
    currentUser: User;
    videos: any = null;
    last;
    end_pagination = true;
    page = 1;
    videos_data;
    category;
    filter;
    popup = 0;
    filter_text = 'All Categories';
    ready = false;
    for_users=[]


    constructor(private userService: UserService,
        private authenticationService: AuthenticationService,
        private alertService: AlertService,
        private router: Router,
        private http: HttpClient,
        private route: ActivatedRoute
    ) {
        this.currentUser = JSON.parse(localStorage.getItem('currentUser'));

    }


    ngOnInit() {

        let currentUser = JSON.parse(localStorage.getItem('currentUser'));
        if (currentUser) {
            if (currentUser.category == "Admin") {
                this.router.navigate(['/admin/users']);
            } else {
                this.router.navigate(['/home']);
            }
        }

        this.http.get(`${environment.apiUrl}/videos`)
            .subscribe(
                data => {
                    this.videos = data;
                    this.page = this.videos.meta.current_page;
                    this.videos_data = this.videos.data;
                    this.last = this.videos.meta.last_page;
                    this.ready = true;
                    if (this.last == this.page) {
                        this.end_pagination = false;
                    }
                },
                error => {

                }
            );
        this.http.get(`${environment.apiUrl}/video/categories`)
            .subscribe(
                data => {
                    this.category = data;

                },
                error => {

                }
            );


    }

    playvideo() {
        this.alertService.video("https://player.vimeo.com/video/60855585?portrait=0");
    }



    private logout() {
        this.authenticationService.logout();
    }
    go(item) {
        console.log(item)
        this.for_users=Object.values(item.plans);
        if (!item.available) {
            this.popup = 1;
        }else{
            this.router.navigate(['/video/'+item.id]);
        }
    }
    hide_popup(){
        this.popup=0
    }
    upgrade(){
            this.router.navigate(['/pricing']);
    }
    reloadVideos(f = null, text) {
        this.page = 1;
        this.filter_text = text;
        this.end_pagination = true;
        if (f) {
            this.filter = f;
            this.http.get(`${environment.apiUrl}/videos?category_id=` + f)
                .subscribe(
                    data => {
                        this.videos = data;
                        this.page = this.videos.meta.current_page;
                        this.videos_data = this.videos.data;
                        this.last = this.videos.meta.last_page;;
                        if (this.last == this.page) {
                            this.end_pagination = false;
                        }

                    },
                    error => {

                    }
                );
        } else {
            this.filter = null;
            this.http.get(`${environment.apiUrl}/videos`)
                .subscribe(
                    data => {
                        this.videos = data;
                        this.page = this.videos.meta.current_page;
                        this.videos_data = this.videos.data;
                        this.last = this.videos.meta.last_page;

                    },
                    error => {

                    }
                );
        }

    }
    next_page() {
        this.page = this.page + 1;
        if (this.filter) {
            this.http.get(`${environment.apiUrl}/videos?category_id=` + this.filter + `&page=` + this.page)
                .subscribe(
                    data => {
                        this.videos = data;
                        this.page = this.videos.meta.current_page;
                        this.last = this.videos.meta.last_page;
                        this.videos_data = this.videos_data.concat(this.videos.data);

                        if (this.last == this.page) {
                            this.end_pagination = false;
                        }
                    },
                    error => {

                    }
                );
        } else {
            this.http.get(`${environment.apiUrl}/videos?page=` + this.page)
                .subscribe(
                    data => {
                        this.videos = data;
                        this.page = this.videos.meta.current_page;
                        this.last = this.videos.meta.last_page;
                        this.videos_data = this.videos_data.concat(this.videos.data);

                        if (this.last == this.page) {
                            this.end_pagination = false;
                        }
                    },
                    error => {

                    }
                );
        }

    }



    customAnimation = [
        style({ opacity: 0, transform: 'rotate(355deg)' }),
        animate('600ms cubic-bezier(0.35, 0, 0.25, 1)', style({ opacity: 1, transform: 'rotate(0deg)' }))
    ];


}