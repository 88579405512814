import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService, AlertService } from '../../_services';
import { environment } from '../../../environments/environment';
import {
  startOfDay,
  endOfDay,
  subDays,
  addDays,
  endOfMonth,
  isSameDay,
  isSameMonth,
  addHours
} from 'date-fns';

import { DatePipe } from '@angular/common';
import { User } from '../../_models';
import { NavbarComponent } from '../../navbar/navbar.component';
declare var $: any;
@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss']
})
export class UserProfileComponent implements OnInit {
  errors = new Map();
  updateProfile: FormGroup;
  updateProfileDocs: FormGroup;
  loading = false;
  submitted = false;
  currentUser;
  user_active;
  image;
  file;
  display_msg=false;
  doc_name;
  parentInput;
  file_uploading=false;
  image_uploading=false;
  pro;
  premium;
    loading_downgrade=false;
    grace_period;
    end_date = "";


    refer_src = "../../../assets/img/iconReferAFriend.svg";
    pay_src = "../../../assets/img/iconPayMonthly.svg";
    divItems;
    active_item = 0;
    upgrade_popup = false;
    downgrade_popup = false;

    unpaid;
    unpaid_end;
    days_left;
    pipe = new DatePipe('en-US');
    today;
    loading_freeupgrade = false;
    subscribed;

    free_upgrade;

  constructor(
    private http: HttpClient,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private userService: UserService,
    private alertService: AlertService
  ) {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this.parentInput=this.currentUser;
  }
  @Output() eventClicked = new EventEmitter<Event>();

  //More app code

 

  ngOnInit() {

    this.divItems = document.getElementsByClassName("popup_rectangle");

    this.updateProfile = this.formBuilder.group({
      name: ['', Validators.required],
      email: [''],
      category: [''],
      grad_date: ['']
    });
    this.updateProfileDocs = this.formBuilder.group({
      image: ['']
    });

    this.updateProfile.get('name').setValue(this.currentUser.name);
    this.updateProfile.get('email').setValue(this.currentUser.email);
    this.updateProfile.get('category').setValue(this.currentUser.category);
    this.updateProfile.get('grad_date').setValue(this.currentUser.graduation_date);
    this.user_active = this.currentUser.active;
    this.pro = this.currentUser.pro;
    this.premium = this.currentUser.plan_id==3;
    this.grace_period = this.currentUser.grace_period;
    this.end_date = this.currentUser.subscription_end;

    this.unpaid = this.currentUser.unpaid;
    this.subscribed = this.currentUser.subscribed;
    this.free_upgrade = this.currentUser.free_upgrade;
    if(this.unpaid) {
        this.unpaid_end = this.addDays(this.end_date, 30);
        this.days_left = this.getDaysLeft(this.unpaid_end);
    }    
    
  }

    addDays(date, days) {
        var result = new Date(date);
        result.setDate(result.getDate() + days);
        return result;
      }

      getDaysLeft(date2) {
        var dt1 = new Date();
        var dt2 = new Date(date2);
        return Math.floor((Date.UTC(dt2.getFullYear(), dt2.getMonth(), dt2.getDate()) - Date.UTC(dt1.getFullYear(), dt1.getMonth(), dt1.getDate()) ) /(1000 * 60 * 60 * 24));
      }

  get f() { return this.updateProfile.controls; }

  onSubmit() {
    this.submitted = true;

    if (this.updateProfile.invalid) {
      return;
    }
    this.loading = true;

    this.userService.update(this.f)
      .subscribe(
        data => {
          this.alertService.success(data.message, true);
          this.currentUser.name = this.f.name.value;
          this.currentUser.graduation_date = this.f.grad_date.value;
         
          localStorage.setItem('currentUser', JSON.stringify(this.currentUser));
          this.loading = false;
        },
        error => {
          this.errors = error;
          this.alertService.error(error.get('message'));
          this.loading = false;
          
        });

  }
  trigerImageUpload() {
    $("#imageupload:hidden").trigger('click');
  }

  imageChanged(event) {
    let file = event.target.files[0];
    if(file.size/1000000>2){
      this.alertService.error("Profile Image must be under 2MB");
      return;
    }
    const fd = new FormData();
    fd.append('avatar', file, file.name);
   this.image_uploading=true;
    this.userService.avatar(fd)
      .subscribe(
        data => {
          this.alertService.success(data.message, true);
          this.currentUser.icon =data.icon;
          this.currentUser.thumbnail =data.thumbnail;
          localStorage.setItem('currentUser', JSON.stringify(this.currentUser));
          this.parentInput.thumbnail=data.thumbnail;
          this.parentInput.icon=data.icon;
          this.image_uploading=false;
        },
        error => {
          this.errors = error;
          this.alertService.error(error.get('message'));
          this.loading = false;
          this.image_uploading=false;
        });
  }

  

  diplayMsg(){
      if(this.display_msg){
        this.display_msg=false;
      }else{
        this.display_msg=true;
      }
      
  }
  resendActivation(){
    this.userService.reactivate()
    .subscribe(
      data => {
        this.alertService.success(data.message, true);    
        this.display_msg=false;  
      },
      error => {     
        this.alertService.error(error.get('message'));  
        this.display_msg=false;     
      });
  }
    upgrade() {
        if(!this.unpaid) {
            if(this.currentUser.free_upgrade) {
                this.loading_freeupgrade = true;

                this.http.post(`${environment.apiUrl}/user/free-upgrade`, {})
            .subscribe(
              (data: any) => {
                              
                this.alertService.success(data.message, true);
                this.loading_freeupgrade = false;
                
                this.pro = true;
                this.currentUser.pro = true

                localStorage.setItem('currentUser', JSON.stringify(this.currentUser));
              },
              error => {
                this.loading_freeupgrade = false;
                this.errors = error;
                this.alertService.error(error.get('message'));
              }
            )
            } else {
              
       this.router.navigate(['/pricing'])
            }
        } else {
            this.go_to_upgrade();
        }    
    }
    hide_popup() {
        this.upgrade_popup = false;

        this.refer_src = "../../../assets/img/iconReferAFriend.svg";
        this.pay_src = "../../../assets/img/iconPayMonthly.svg";
        this.active_item = 0;
  }

    go_to_upgrade() {
        this.router.navigate(['/payment'], { queryParams: { type: 1 } });
    }

    downgrade() {
       // this.downgrade_popup = true;
       this.router.navigate(['/pricing'])
    }

    hide_downgrade() {
        this.downgrade_popup = false;
    }

    downgradeSubmit() {
        this.loading_downgrade = true

        if(!this.grace_period) {
            this.http.post(`${environment.apiUrl}/user/unsubscribe`, {})
            .subscribe(
              (data: any) => {
                              
                this.alertService.success(data.message, true);
                this.loading_downgrade = false;
                
                if(!data.free_pro) {
                    this.end_date = data.end_date;
                    this.grace_period = true;
                    this.currentUser.grace_period = true;
                    this.currentUser.subscription_end = data.end_date;
                    
                } else {
                    this.pro = false;
                    this.currentUser.pro = false;
                }

                localStorage.setItem('currentUser', JSON.stringify(this.currentUser));

                this.downgrade_popup = false;
              },
              error => {
                this.loading_downgrade = false;
                this.errors = error;
                this.alertService.error(error.get('message'));
                this.downgrade_popup = false;
              }
            )
        } else {
            this.http.post(`${environment.apiUrl}/user/resume-subscription`, {})
            .subscribe(
              (data: any) => {

                this.alertService.success(data.message, true);
                this.loading_downgrade = false;

                this.grace_period = false;
                this.currentUser.grace_period = false;
                this.end_date = null;
                this.currentUser.subscription_end = null;
                localStorage.setItem('currentUser', JSON.stringify(this.currentUser));

                this.downgrade_popup = false;
              },
              error => {
                this.loading_downgrade = false;
                this.errors = error;
                this.alertService.error(error.get('message'));
                this.downgrade_popup = false;
              }
            )
        }
    }

    selected(item, active_item) {  
        this.active_item = active_item;
        this.clear();
        item.style.backgroundColor = '#DC5D63';
        if(active_item == 1) {
            this.pay_src = "../../../assets/img/iconPayMonthlySelected.svg";
            this.refer_src = "../../../assets/img/iconReferAFriend.svg";
        } else {
            this.refer_src = "../../../assets/img/iconReferAFriendSelected.svg";
            this.pay_src = "../../../assets/img/iconPayMonthly.svg";
        }
    }
    clear() {
        for(var i=0; i < this.divItems.length; i++) {
            var item = this.divItems[i];
            item.style.backgroundColor = 'white';
        }
    }

}
