import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { AlertService } from '../../../_services';
import { environment } from '../../../../environments/environment';


@Component({
  selector: 'app-create',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.scss']
})
export class CreateComponent implements OnInit {
  category;
  roles;
  speakers;
  v_category = new Map();
  v_category_text = "Choose Category";
  v_roles = new Map();
  v_roles_text = "Choose User Type";
  v_speakers = new Map();
  v_speakers_text = "Choose Speaker";
  v_applicable_text="Choose User Package";
  question_id = 0;
  questions = [{ id: 0, value: "", correct: "", invalid: '', options: [{ id: 0, value: "" }, { id: 1, value: "" }] }];
  chars = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];
  form;
  video_name = "";
  video_url = "";
  video_desc = "";
  date_available;
  home;
  api_category = [];
  v_applicable=[];
  api_roles = [];
  api_speaker = "";
  api_questions = [];
  views;
  form_sumbmited = false;
  invalid_link='';
  loading=false;

  constructor(
    private router: Router,
    private http: HttpClient,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private alertService: AlertService,
  ) { }

  ngOnInit() {
   
    this.form = this.formBuilder.group({});
    this.http.get(`${environment.apiUrl}/video/categories`)
      .subscribe(
        data => {
          this.category = data;
        }
      );
    this.http.get(`${environment.apiUrl}/admin/video/create`)
      .subscribe(
        data => {
          let d: any = data;
          this.speakers = d.speakers;
          this.roles = d.user_types;
        }
      );
  }

  addQuestion() {
    this.question_id++;
    this.questions.push({ id: this.question_id, value: "", invalid: '', correct: '', options: [{ id: 0, value: "" }, { id: 1, value: "" }] });
  }
  addAnswers(id) {
    const new_id = this.questions[id].options[this.questions[id].options.length - 1].id;
    this.questions[id].options.push({ id: new_id + 1, value: "" });
  }
  removeAnswers(q) {
    this.questions[q].options.pop();
  }
  applicableChange(id){
    const index = this.v_applicable.indexOf(id);
    if (index > -1) {
      this.v_applicable.splice(index, 1);
    } else {
      this.v_applicable.push(id);
    }
this.v_applicable_text=""
if(this.v_applicable.length>0){
  if(this.v_applicable.indexOf(1)>-1){
    this.v_applicable_text="BASIC, "
  }
  if(this.v_applicable.indexOf(2)>-1){
    this.v_applicable_text+="PRO, "
  }
  if(this.v_applicable.indexOf(3)>-1){
    this.v_applicable_text+="PREMIUM"
  }
}else{
  this.v_applicable_text="Choose User Package"
}

    console.log(this.v_applicable)
  }

  CategoryChange(values: any, type, id) {
    this.v_category.set(type, values.currentTarget.checked)
    if (values.currentTarget.checked) {
      this.api_category.push(id);
    } else {
      this.api_category.splice(this.api_category.indexOf(id), 1);
    }

    this.v_category_text = "";
    this.v_category.forEach((value: boolean, key: string) => {

      if (value) {
        if(this.v_category_text=="")
        {
          this.v_category_text += key;
        }else{
        this.v_category_text += ", "+key;
        }
      }
    });

  }

  RoleChange(values: any, type, id) {
    this.v_roles.set(type, values.currentTarget.checked)
    if (values.currentTarget.checked) {
      this.api_roles.push(id);
    } else {
      this.api_roles.splice(this.api_roles.indexOf(id), 1);
    }

    this.v_roles_text = "";
    this.v_roles.forEach((value: boolean, key: string) => {

      if (value) {
        if(this.v_roles_text=="")
        {
          this.v_roles_text += key;
        }else{
        this.v_roles_text += ", "+key;
        }
      }
    });

  }

  SpeakerChange(values: any, type, id) {
    this.v_speakers.clear();
    this.v_speakers.set(type, values.currentTarget.checked)
    this.api_speaker = id;
    this.v_speakers_text = "";
    this.v_speakers.forEach((value: boolean, key: string) => {

      if (value) {
        this.v_speakers_text += key;
      }
    });

  }

  onSubmit(form) {
    this.form_sumbmited = true;
    if (this.validate_form()) {
      return;
    }
    this.questions_prepare();
    this.loading=true;
    this.http.post(`${environment.apiUrl}/admin/video/store`, {
      name: this.video_name,
      link: this.video_url,
      description: this.video_desc,
      categories: this.api_category,
      user_types: this.api_roles,
      speaker: this.api_speaker,
      views: this.views,
      date_available:this.date_available,
      plans:this.v_applicable,
      homepage_show: this.home ? 1 : 0,
      questions: this.api_questions
    })
      .subscribe(
        (data: any) => {
          this.alertService.success(data.message, true);
          this.router.navigateByUrl('/admin/videos');
          this.loading=false;
        },
        error => {
          this.loading=false;
        }
      )
  }

  questions_prepare() {  
    this.questions.forEach(element => {
      if (element.value) {
        let opt = [];
        element.options.forEach(element => {
          if(element.value != ""){
            opt.push(element.value);
          }
        });
        this.api_questions.push({
          content: element.value,
          correct: element.correct,
          options: opt
        });
      }
    });

  }
  removeQuestion(i){
    this.questions.splice(i, 1);
  }

  corect(q_id, a_id) {
    this.questions[q_id].correct = a_id;
  }

  validate_form() {
    let status = false;

    if (this.video_name == "" || this.video_url == "" || this.video_desc == "") {
      status = true;
    }
    if(!this.video_url.startsWith('https://player.vimeo.com/video/')){
      status = true;
      this.invalid_link="Video URL must be in the following format: https://player.vimeo.com/video/123456"
    }else{
      this.invalid_link='';
    }
    if (this.api_category.length == 0 || this.api_roles.length == 0 || this.api_speaker == "") {
      status = true;
    }
    if(!((parseFloat(this.views) == parseInt(this.views)) && !isNaN(this.views))){
      status = true;
    }

    this.questions.forEach(element => {
      element.invalid = '';
      if (element.value != "") {
        if (element.correct === "" || element.correct == "/"|| !element.options[element.correct] ) {
          element.invalid = 'Please select a correct answer';
          status = true
        }
        if (element.options[0].value == "" || element.options[1].value == "") {
          element.invalid = 'Please add at least two options';
          status = true;
        }
      }
    });
    return status;
  }

  isNotInteger(){

    if(this.views==''|| !this.views){
      return false;
    }

     if(!((parseFloat(this.views) == parseInt(this.views)) && !isNaN(this.views))){
      return  true;
     }
     return false;
  }

  validateUrl(){
    if(!this.video_url.startsWith('https://player.vimeo.com/video/')){      
      this.invalid_link="Video URL must be in the following format: https://player.vimeo.com/video/123456"
    }else{
      this.invalid_link='';
    }
  }
}
