import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

  include_links=false;
  id=0;

  constructor(private router: Router) { }

  ngOnInit() {
    if(this.router.url=="/" ||this.router.url=="/contact-us" ||this.router.url=="/about-us"||this.router.url=="/what-we-do" ){
      this.include_links=true;
    }

    if(this.router.url.includes('contact-us')){
      this.id=3;
    }
    if(this.router.url.includes('what-we-do')){
      this.id=2;
    }
    if(this.router.url.includes('about-us')){
      this.id=1;
    }


  }
  addClass(id){
    this.id=id;
  }

}
