import { Component, OnInit,NgModule } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { Router } from '@angular/router';
import { AlertService } from 'src/app/_services';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-create',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.scss']
})
export class CreateComponent implements OnInit {

  constructor(private http: HttpClient, private router: Router, private alertService: AlertService) { }
  webinar_name;
  webinar_time;
  webinar_date;
  webinar_desc = "";
  form_submited = false;
  loading = false;
  errors = new Map();
  pipe = new DatePipe('en-US');
  today = this.pipe.transform(new Date(), 'MM/dd/yyy');
  invalid_date = "";
  question_id = 0;
  questions = [{ id: 0, value: "", correct: "", invalid: '', options: [{ id: 0, value: "" }, { id: 1, value: "" }] }];
  api_questions = [];
  chars = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];

  webinar_types;
  roles;
  v_roles = new Map();
  v_roles_text = "Choose Categories";
  api_roles = [];

  v_types = new Map();
  v_types_text = "Choose Type";
  api_type = "";

  webinar_price;

  ngOnInit() {
    this.http.get(`${environment.apiUrl}/webinar-types`)
      .subscribe(
        (data: any) => {
          this.webinar_types = data;
        })

    this.http.get(`${environment.apiUrl}/user-categories`)
      .subscribe(
        (data: any) => {
          this.roles = data;
        })
  }

  RoleChange(values: any, type, id) {
    this.v_roles.set(type, values.currentTarget.checked)
    if (values.currentTarget.checked) {
      this.api_roles.push(id);
    } else {
      this.api_roles.splice(this.api_roles.indexOf(id), 1);
    }

    this.v_roles_text = "";
    this.v_roles.forEach((value: boolean, key: string) => {

      if (value) {
        if (this.v_roles_text == "") {
          this.v_roles_text += key;
        } else {
          this.v_roles_text += ", " + key;
        }
      }
    });

  }

  addQuestion() {
    this.question_id++;
    this.questions.push({ id: this.question_id, value: "", invalid: '', correct: '', options: [{ id: 0, value: "" }, { id: 1, value: "" }] });
  }
  addAnswers(id) {
    const new_id = this.questions[id].options[this.questions[id].options.length - 1].id;
    this.questions[id].options.push({ id: new_id + 1, value: "" });
  }
  removeAnswers(q) {
    this.questions[q].options.pop();
  }questions_prepare() {  
    this.questions.forEach(element => {
      if (element.value) {
        let opt = [];
        element.options.forEach(element => {
          if(element.value != ""){
            opt.push(element.value);
          }
        });
        this.api_questions.push({
          content: element.value,
          correct: element.correct,
          options: opt
        });
      }
    });

  }
  removeQuestion(i){
    this.questions.splice(i, 1);
  }

  corect(q_id, a_id) {
    this.questions[q_id].correct = a_id;
  }

  validate_form() {
    let status = false;


    this.questions.forEach(element => {
      element.invalid = '';
      if (element.value != "") {
        if (element.correct === "" || element.correct == "/"|| !element.options[element.correct] ) {
          element.invalid = 'Please select a correct answer';
          status = true
        }
        if (element.options[0].value == "" || element.options[1].value == "") {
          element.invalid = 'Please add at least two options';
          status = true;
        }
      }
    });
    return status;
  }

 


  TypeChange(values: any, type, id) {
    this.v_types.clear();
    this.v_types.set(type, values.currentTarget.checked)
    this.api_type = id;
    this.v_types_text = "";

    if (id == 3) {
      (document.getElementById("webinarPrice") as any).disabled = false;
    } else {
      (document.getElementById("webinarPrice") as any).disabled = true;
    }

    this.v_types.forEach((value: boolean, key: string) => {

      if (value) {
        this.v_types_text += key;
      }
    });

  }

  submit() {

    if (this.webinar_date) {
      if (this.validateDate()) {
        return;
      }
    }
    this.questions_prepare();


    this.form_submited = true;
    this.loading = true;
    this.http.post(`${environment.apiUrl}/admin/webinar/store`, {
      name: this.webinar_name,
      description: this.webinar_desc,
      date: (this.webinar_date) ? this.pipe.transform(this.webinar_date, 'MM/dd/yyyy') : null,
      time: this.webinar_time,
      user_types: this.api_roles,
      webinar_type: this.api_type,
      price: this.webinar_price,
      questions: this.api_questions
    })
      .subscribe(
        (data: any) => {
          this.alertService.success(data.message, true);
          this.router.navigateByUrl('/admin/webinars');
          this.loading = false;
        },
        error => {
          this.loading = false;
          this.errors = error;
          this.alertService.error(error.get('message'));
        }
      )
  }

  validateDate() {
    let date1 = new Date(this.webinar_date);
    let date2 = new Date(this.today);
    if (isNaN(Date.parse(this.webinar_date))) {
      this.invalid_date = "Invalid date"
      console.log("yes");
      return true
    }

    if (date1 < date2) {
      this.invalid_date = "You can not create a webinar on a date that has already passed."
      console.log("yes");
      return true
    }
    else {

      console.log(this.webinar_date, this.today);
      this.invalid_date = "";
      return false
    }
  }


}
