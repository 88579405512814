﻿import { Routes, RouterModule } from '@angular/router';

import { HomeComponent } from './home';
import { LoginComponent } from './login';
import { RegisterComponent } from './register';
import { ResetComponent } from './reset/reset.component';
import { IndexComponent } from './index/index.component';
import { ActivateComponent } from './activate/activate.component';
import { AuthGuard } from './_guards';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { VideoComponent } from './video/video.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { TermsComponent } from './terms/terms.component';
import { WebinarsComponent } from './webinars/webinars.component';
import { DetailsComponent } from './webinars/details/details.component';
import { CommentsComponent as AdminWebinarComments } from './admin/webinars/comments/comments.component';
import { CopyrightComponent } from './copyright/copyright.component';
import { WhatWeDoComponent } from './what-we-do/what-we-do.component';
import { UserProfileComponent } from './profile/user-profile/user-profile.component';
import { ChangePasswordComponent } from './profile/change-password/change-password.component';
import { PricingComponent } from './profile/pricing/pricing.component';
import { TestComponent } from './test/test.component';
import { TestSurveyComponent } from './test-survey/test-survey.component';
import { SurveyComponent } from './survey/survey.component';
import { CertificatesComponent } from './profile/certificates/certificates.component';
import { DocumentsComponent } from './profile/documents/documents.component';
import { ReferFriendComponent } from './profile/refer-friend/refer-friend.component';
import { BillingInfoComponent } from './profile/billing-info/billing-info.component';
import { InvoicesComponent } from './profile/invoices/invoices.component';
import { InviteComponent } from './profile/invite/invite.component';
import { ResendComponent } from './profile/invite/resend/resend.component';
import { UpgradeComponent } from './profile/upgrade/upgrade.component';
import { UsersComponent } from './admin/users/users.component';
import { VideosComponent } from './admin/videos/videos.component';
import { CommentsComponent } from './admin/comments/comments.component';
import { IndexComponent as SpeakerIndex } from './admin/speaker/index/index.component';
import { EditSpeakerComponent } from './admin/speaker/edit-speaker/edit-speaker.component';
import { AddSpeakerComponent } from './admin/speaker/add-speaker/add-speaker.component';
import { CreateComponent } from './admin/videos/create/create.component';
import {EditComponent } from './admin/videos/edit/edit.component';
import {CategoriesComponent } from './admin/categories/categories.component';
import {PasswordComponent } from './admin/password/password.component';
import {WebinarsComponent as AdminWebinars } from './admin/webinars/webinars.component';
import {SurveyComponent as AdminSurvey } from './admin/survey/survey.component';
import {UsersComponent as WebinarUsers } from './admin/webinars/users/users.component';
import {CreateComponent as CreateWebinar } from './admin/webinars/create/create.component';
import {EditComponent as EditWebinar } from './admin/webinars/edit/edit.component';
import { AdminGuard } from './admin.guard';
import {StatusComponent } from './status/status.component';

const appRoutes: Routes = [
    { path: '', component: IndexComponent },
    { path: 'home', component: HomeComponent, canActivate: [AuthGuard] },
    { path: 'login', component: LoginComponent },
    { path: 'register', component: RegisterComponent },
    { path: 'register/:ref', component: RegisterComponent },
    { path: 'reset', component: ResetComponent },
    { path: 'profile', component: UserProfileComponent ,canActivate: [AuthGuard]  },
    { path: 'contact-us', component: ContactUsComponent },
    { path: 'about-us', component: AboutUsComponent },
    { path: 'what-we-do', component: WhatWeDoComponent },
    { path: 'copyright', component: CopyrightComponent},
    { path: 'privacy', component: PrivacyComponent },
    { path: 'terms', component: TermsComponent },
    { path: 'video/:id', component: VideoComponent ,canActivate: [AuthGuard]  },
    { path: 'password/reset/:token', component: ResetPasswordComponent },
    { path: 'activate/:token', component: ActivateComponent },
    { path: 'change-password', component: ChangePasswordComponent },
    { path: 'pricing', component: PricingComponent },
    { path: 'test/survey', component: TestSurveyComponent,canActivate: [AuthGuard]  },
    { path: 'webinars', component: WebinarsComponent,canActivate: [AuthGuard]  },
    { path: 'webinars/:id', component: DetailsComponent,canActivate: [AuthGuard]  },
    { path: 'test/:id', component: TestComponent,canActivate: [AuthGuard]  },
    { path: 'survey', component: SurveyComponent ,canActivate: [AuthGuard] },
    { path: 'certificates', component: CertificatesComponent,canActivate: [AuthGuard]  },
    { path: 'documents', component: DocumentsComponent,canActivate: [AuthGuard]  },
    { path: 'refer-friend', component: ReferFriendComponent,canActivate: [AuthGuard]  },
    { path: 'billing-info', component: BillingInfoComponent,canActivate: [AuthGuard]  },
    { path: 'invoices', component: InvoicesComponent,canActivate: [AuthGuard]  },
    { path: 'invite', component: InviteComponent,canActivate: [AuthGuard]  },
    { path: 'invite/:id', component: InviteComponent,canActivate: [AuthGuard]  },
    { path: 'invite/resend/:id', component: ResendComponent,canActivate: [AuthGuard]  },
    { path: 'payment', component: UpgradeComponent,canActivate: [AuthGuard]  },
    { path: 'admin/webinars/comment/:id', component: AdminWebinarComments ,canActivate: [AdminGuard] },
    { path: 'admin/users', component: UsersComponent ,canActivate: [AdminGuard] },
    { path: 'admin/videos', component: VideosComponent ,canActivate: [AdminGuard] },
    { path: 'admin/videos/create', component: CreateComponent ,canActivate: [AdminGuard] },
    { path: 'admin/videos/:id/edit', component: EditComponent ,canActivate: [AdminGuard] },
    { path: 'admin/comments/:id', component: CommentsComponent ,canActivate: [AdminGuard] },
    { path: 'admin/speakers', component: SpeakerIndex ,canActivate: [AdminGuard] },
    { path: 'admin/speaker/add', component: AddSpeakerComponent ,canActivate: [AdminGuard] },
    { path: 'admin/speaker/:id', component: EditSpeakerComponent ,canActivate: [AdminGuard] },
    { path: 'admin/categories', component: CategoriesComponent ,canActivate: [AdminGuard] },
    { path: 'admin/password', component: PasswordComponent ,canActivate: [AdminGuard] },
    { path: 'admin/webinars', component: AdminWebinars ,canActivate: [AdminGuard] },
    { path: 'admin/survey/questions', component: AdminSurvey ,canActivate: [AdminGuard] },
    { path: 'admin/webinars/list/:id', component: WebinarUsers ,canActivate: [AdminGuard] },
    { path: 'admin/webinars/create', component: CreateWebinar ,canActivate: [AdminGuard] },
    { path: 'admin/webinars/:id', component: EditWebinar ,canActivate: [AdminGuard] },
    { path: 'user/payment-status', component: StatusComponent,canActivate: [AuthGuard]  },

    // otherwise redirect to home
    { path: '**', redirectTo: '' }
];

export const routing = 
RouterModule.forRoot(appRoutes,
    {
       onSameUrlNavigation: 'reload'
     }
 );