import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Router } from '@angular/router';
import { isThisQuarter } from 'date-fns';


@Component({
  selector: 'app-pricing',
  templateUrl: './pricing.component.html',
  styleUrls: ['./pricing.component.scss']
})
export class PricingComponent implements OnInit {
  currentUser;
  pro;
  pro_m;
  plan_id;
  payment_popup;
  premium_popup;
  premium;
  discount_premium=false;
  to_type;
  divItems;
  active_item = 0;
  upgrade_popup = false;
  loading_downgrade=false;
  downgrade_popup = false;
  downgrade_to="BASIC"
  grace_period;
  refer_src = "../../../assets/img/iconReferAFriend.svg";
  pay_src = "../../../assets/img/iconPayMonthly.svg";
  pay_src_m = "../../../assets/img/iconPayMonth.svg";
  pay_src_y = "../../../assets/img/iconPayAnual.svg";

  constructor(private http: HttpClient,
    private router: Router,) {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));

  }

  ngOnInit() {
    this.divItems = document.getElementsByClassName("popup_rectangle");

    this.http.get(`${environment.apiUrl}/video/certificates`)
      .subscribe(
        data => {

        },
        error => {

        }
      );
    this.pro = this.currentUser.pro;
    this.plan_id = this.currentUser.plan_id;
    this.premium=this.plan_id==3;
    this.pro_m=this.currentUser.payment=='annually'?0:1;
    this.grace_period = this.currentUser.grace_period;
    this.discount_premium=this.currentUser.discount_premium;
  }
  upgrade(type) {
    this.upgrade_popup = true;
    this.to_type = type;
  }
  upgrade_premium(){
    this.premium_popup=1
  }
  go_to_upgrade(annually,premium) {
    this.router.navigate(['/payment'], { queryParams: { type:1 ,annualy:annually,premium:premium } });
  }
  
  downgrade(type) {
    this.downgrade_popup = true;
    if(type==2){
      this.downgrade_to="PRO";
    }else{
      this.downgrade_to="BASIC";
    }
  }
  switch(type,annually){
    let to_pro =0;
    let to_premium=0
  if(type=='pro'){
    to_pro=1
    to_premium=0
  }else{
    to_pro=0
    to_premium=1
  }
    this.router.navigate(['/payment'], { queryParams: { type:1 ,annualy:1,premium:to_premium } });

  }

  hide_popup() {
    this.upgrade_popup = false;

    this.refer_src = "../../../assets/img/iconReferAFriend.svg";
    this.pay_src = "../../../assets/img/iconPayMonthly.svg";
    this.active_item = 0;
  }
  hide_premium(){
    this.premium_popup = 0
    this.active_item = 0;

    this.pay_src_m = "../../../assets/img/iconPayMonth.svg";
    this.pay_src_y = "../../../assets/img/iconPayAnual.svg";
  }
  hide_upgrade() {
    this.payment_popup = 0
    this.active_item = 0;

    this.pay_src_m = "../../../assets/img/iconPayMonth.svg";
    this.pay_src_y = "../../../assets/img/iconPayAnual.svg";
  }
  choose_upgrade() {
    this.hide_popup();
    this.payment_popup = 1
  }
  hide_downgrade() {
    this.downgrade_popup = false;
  }
  selected_type(item, active_item) {
    this.divItems = document.getElementsByClassName("popup_rectangle");

    this.active_item = active_item;
    this.clear();
    item.style.backgroundColor = '#DC5D63';
    if (active_item == 1) {
      this.pay_src_m = "../../../assets/img/iconPayMonthSelected.svg";

      this.pay_src_y = "../../../assets/img/iconPayAnual.svg";
    } else {
      this.pay_src_y = "../../../assets/img/iconPayAnnuallySelected.svg";
      this.pay_src_m = "../../../assets/img/iconPayMonth.svg";
    }
  }
  selected(item, active_item) {
    this.active_item = active_item;
    this.clear();
    item.style.backgroundColor = '#DC5D63';
    if (active_item == 1) {
      this.pay_src = "../../../assets/img/iconPayMonthlySelected.svg";
      this.refer_src = "../../../assets/img/iconReferAFriend.svg";
    } else {
      this.refer_src = "../../../assets/img/iconReferAFriendSelected.svg";
      this.pay_src = "../../../assets/img/iconPayMonthly.svg";
    }
  }
  clear() {
    for (var i = 0; i < this.divItems.length; i++) {
      var item = this.divItems[i];
      item.style.backgroundColor = 'white';
    }
  }

  downgradeSubmit() {
    this.loading_downgrade = true

    if(!this.grace_period) {
      let tmp='/user/unsubscribe';
      if(this.premium && this.downgrade_to=='PRO'){
        tmp='/user/subscription/switch-plan';
        if(this.currentUser.payment=='annually'){
          tmp+='?annually=1&pro=1';
        }else{
          tmp+='?annually=0&pro=1';
        }
      }
        this.http.post(environment.apiUrl+tmp, {})
        .subscribe(
          (data: any) => {
            this.loading_downgrade = false;
            
            if(!data.free_pro) {
                this.grace_period = true;
                this.currentUser.grace_period = true;
                this.currentUser.subscription_end = data.end_date;
                
            } else {
                this.pro = false;
                this.currentUser.pro = false;
            }

            localStorage.setItem('currentUser', JSON.stringify(this.currentUser));

            this.downgrade_popup = false;

            this.router.navigate(['/profile']);
          },
          error => {
            this.loading_downgrade = false;
            this.downgrade_popup = false;
          }
        )
    } else {
        this.http.post(`${environment.apiUrl}/user/resume-subscription`, {})
        .subscribe(
          (data: any) => {

            this.loading_downgrade = false;

            this.grace_period = false;
            this.currentUser.grace_period = false;
            this.currentUser.subscription_end = null;
            localStorage.setItem('currentUser', JSON.stringify(this.currentUser));

            this.downgrade_popup = false;

            this.router.navigate(['/profile']);
          },
          error => {
            this.loading_downgrade = false;
            this.downgrade_popup = false;
          }
        )
    }
}

}
