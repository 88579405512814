import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params, Route } from '@angular/router';
import { AlertService } from '../_services';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';


@Component({
  selector: 'app-status',
  templateUrl: './status.component.html',
  styleUrls: ['./status.component.scss']
})
export class StatusComponent implements OnInit {

  token;

  constructor(

    private route: ActivatedRoute,
    private router: Router,
    private alertService: AlertService,
    private http: HttpClient
  ) { }

  ngOnInit() {

    this.route.queryParams.subscribe((params: Params) => this.token = params['payment_intent']);
    this.http.post<any>(`${environment.apiUrl}/user/check-payment`, { payment_intent_id: this.token })
      .subscribe(
        data => {
          console.log(data)
          this.alertService.success(data.message, true);
          if (data.redirect) {
            window.location.href = data.redirect;
          } else
            this.router.navigate(['/pricing']);
        },
        error => {

        }
      );
  }

}
