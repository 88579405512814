import { Component, OnInit } from '@angular/core';
import { AlertService } from '../../../_services';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.scss']
})
export class IndexComponent implements OnInit {

  speakers: any = null;
  last;
  end_pagination=true;
  speakers_data;
  page;
  full_text=true;

  constructor(
    private alertService: AlertService,
    private router: Router,
    private http: HttpClient,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {

    let currentUser = JSON.parse(localStorage.getItem('currentUser'));
    if (currentUser) {
        if(currentUser.category=="Admin"){
            //this.router.navigate(['/admin/users']);
        }else{
        this.router.navigate(['/home']);
        }
    }

    this.http.get(`${environment.apiUrl}/admin/speakers`)
    .subscribe(
        data => {
            this.speakers = data;
            this.page = this.speakers.meta.current_page;
            this.speakers_data = this.speakers.data;
            this.last = this.speakers.meta.last_page;
            this.speakers_data.forEach(element => {
              element.show_more=true;
          });
            if (this.last == this.page) {
                this.end_pagination = false;
            }
        },
        error => {
          
        }
    );
  }
  full_text_togle(){
    this.full_text=!this.full_text;
}
  next_page(){
    this.page = this.page + 1;
    
    this.http.get(`${environment.apiUrl}/admin/speakers?page=`+this.page)
    .subscribe(
        data => {
          this.speakers = data;
          this.page = this.speakers.meta.current_page;
          this.last = this.speakers.meta.last_page;
          this.speakers_data = this.speakers_data.concat(this.speakers.data);
         
          if (this.last == this.page) {
              this.end_pagination = false;
          }
        },
        error => {
     
        }
    );
  }

  edit(id){   
    this.router.navigate(['/admin/speaker/'+id]);
  }
  

}
