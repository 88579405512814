import { Component, OnInit } from '@angular/core';
import { AlertService } from '../../_services';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-categories',
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.scss']
})
export class CategoriesComponent implements OnInit {
  categories: any = null;
  last;
  end_pagination = true;
  categories_data;
  page;
  edit_cat = false;
  edit_link;
  cat_name;
  cat_id;
  cat_name_error = false;
  delete_link;
  delete_id;
  confirm_delete=false;


  constructor(
    private alertService: AlertService,
    private router: Router,
    private http: HttpClient,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {

    let currentUser = JSON.parse(localStorage.getItem('currentUser'));
    if (currentUser) {
      if (currentUser.category == "Admin") {
        //this.router.navigate(['/admin/users']);
      } else {
        this.router.navigate(['/home']);
      }
    }

    this.http.get(`${environment.apiUrl}/admin/categories`)
      .subscribe(
        data => {
          this.categories = data;
          this.page = this.categories.meta.current_page;
          this.categories_data = this.categories.data;
          this.last = this.categories.meta.last_page;
        
          if (this.last == this.page) {
            this.end_pagination = false;
          }
        },
        error => {
         
        }
      );
  }
  next_page() {
    this.page = this.page + 1;
    this.http.get(`${environment.apiUrl}/admin/categories?page=` + this.page)
      .subscribe(
        data => {
          this.categories = data;
          this.page = this.categories.meta.current_page;
          this.last = this.categories.meta.last_page;
          this.categories_data = this.categories_data.concat(this.categories.data);

          if (this.last == this.page) {
            this.end_pagination = false;
          }
        },
        error => {
        
        }
      );
  }

  edit(link, name) {
    this.edit_cat = true;
    this.cat_id = link;
    this.cat_name = name;
  }

  hide_popup() {
    this.edit_cat = false;
    this.cat_name_error = false;
  }



  store() {
    if (this.cat_name == "") {
      this.cat_name_error = true;
    }
    let url;
    if (this.cat_id) {
      url = `${environment.apiUrl}/admin/category/store/` + this.cat_id
    } else {
      url = `${environment.apiUrl}/admin/category/store`;
    }
    this.http.post(url, { name: this.cat_name })
      .subscribe(
        (data: any) => {
          this.edit_cat = false;
          this.alertService.success(data.message);

          this.http.get(`${environment.apiUrl}/admin/categories`)
            .subscribe(
              data => {
                this.categories = data;
                this.page = this.categories.meta.current_page;
                this.categories_data = this.categories.data;
                this.last = this.categories.meta.last_page;
              
                if (this.last == this.page) {
                  this.end_pagination = false;
                } else {
                  this.end_pagination = true;
                }
                this.cat_name_error = false;
              },
              error => {
               
              }
            );
        },
        error => {

        }
      );

  }

  delete() {
    this.http.post(this.delete_link, { id: this.delete_id })
      .subscribe(
        (data: any) => {
          this.alertService.success(data.message);
          this.http.get(`${environment.apiUrl}/admin/categories`)
            .subscribe(
              data => {
                this.categories = data;
                this.page = this.categories.meta.current_page;
                this.categories_data = this.categories.data;
                this.last = this.categories.meta.last_page;
              
                if (this.last == this.page) {
                  this.end_pagination = false;
                }
                this.cat_name_error = false;
              },
              error => {
               
              }
            );
        },
        error => {
          this.alertService.success(error.get('message'));
        }
      )
      this.confirm_delete=false;
  }

  confirm(link, id) {
    this.delete_link = link;
    this.delete_id = id;
    this.confirm_delete=true;
  }
  hide_popup_delete(){
    this.confirm_delete=false;
  }

}
