import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { AlertService } from '../../_services';
@Component({
  selector: 'app-password',
  templateUrl: './password.component.html',
  styleUrls: ['./password.component.scss']
})
export class PasswordComponent implements OnInit {

  changePassword: FormGroup;
  submitted=false;
  loading=false;
  errors = new Map();
  constructor(
    private formBuilder: FormBuilder,
    private http: HttpClient,
    private alertService: AlertService,
  ) {   
  }

  ngOnInit() {

    this.changePassword = this.formBuilder.group({
      password: ['', Validators.required],
      new_password: ['', Validators.required],
      confirm_password: ['', Validators.required],
  });

  }

  get f() { return this.changePassword.controls; }

  onSubmit() {
    this.submitted = true;

    if (this.changePassword.invalid) {
        return;
    }
    this.loading = true;

    this.http.post<any>(`${environment.apiUrl}/user/password-change`, {current_password: this.f.password.value ,
      password: this.f.new_password.value ,
      password_confirmation: this.f.confirm_password.value ,
    }).subscribe(
        data => {
            this.alertService.success(data.message);
            this.errors.clear();
            this.f.password.setValue('');
            this.f.new_password.setValue('');
            this.f.confirm_password.setValue('');
            this.submitted = false;
        },
        error => {
            this.errors=error;
        }
    );

}

}
