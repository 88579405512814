import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { AlertService } from '../_services';
import { environment } from '../../environments/environment';
import { Location } from '@angular/common';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { trigger, transition, useAnimation } from '@angular/animations';
import { fadeIn } from 'ngx-animate';
import { style,animate,keyframes,query,stagger } from '@angular/animations';

declare var $: any;
declare var Vimeo: any;
@Component({
    selector: 'app-video',
    templateUrl: './video.component.html',
    styleUrls: ['./video.component.scss'],
    animations: [
        trigger('bounce', [transition('* => *', useAnimation(fadeIn))]),


        trigger('listAnimation', [
            transition('* => *', [

                query(':enter', style({ opacity: 0 }), { optional: true }),

                query(':enter', stagger('300ms', [
                    animate('0.8s ease-in', keyframes([
                        style({ opacity: 0, transform: 'translateY(-20px)', offset: 0 }),
                        style({ opacity: .5, transform: 'translateY(20px)', offset: 0.4 }),
                        style({ opacity: 1, transform: 'translateY(0)', offset: 0.8 }),
                    ]))]), { optional: true })
            ])
        ])

    ]

})

export class VideoComponent implements OnInit {

    video: any = null;
    comments: any = null;
    comment: any = null;
    page;
    id;
    link;
    categories;
    last;
    loading = false;
    submitted = false;
    commentForm: FormGroup;
    user;
    end_pagination = true;
    related_videos;
    playVideo = false;
    iframeSrc: SafeUrl;
    start_test = false;
    popup = false;
    rating;
    video_rating;
    description;
    user_rating = 0;
    old_test = false;
    full_text = true;
    
    constructor(
        private router: Router,
        private http: HttpClient,
        private formBuilder: FormBuilder,
        private route: ActivatedRoute,
        private alertService: AlertService,
        private location: Location,
        public sanitizer: DomSanitizer
    ) { }

    ngOnInit() {

        this.route.params.subscribe((params: Params) => this.id = params['id']);
        this.user = JSON.parse(localStorage.getItem('currentUser'));
        this.load_data();

        this.commentForm = this.formBuilder.group({
            comment: ['', Validators.required]
        });

    }

    next_comment_page() {
        this.page = this.page + 1;
        this.http.get(`${environment.apiUrl}/video/comments?page=` + this.page + `&id=` + this.id)
            .subscribe(
                data => {
                    this.comments = data;
                    this.page = this.comments.meta.current_page;
                    this.last = this.comments.meta.last_page;
                    this.comment = this.comment.concat(this.comments.data);

                    if (this.last == this.page) {
                        this.end_pagination = false;
                    }
                },
                error => {

                }
            );
    }

    ratingChange(event) {
        this.rating = event.rating;
    }

    load_data() {
        this.playVideo = false;
        this.http.get(`${environment.apiUrl}/video/show?id=` + this.id)
            .subscribe(
                data => {
                    this.video = data;
                    this.description = this.video.description;
                    this.old_test = this.video.test_continue;
                    this.iframeSrc = this.sanitizer.bypassSecurityTrustResourceUrl(this.video.link + '?autoplay=1');
                    this.categories = Object.entries(this.video.categories).map(([type, value]) => ({ type, value }));
                    this.video_rating = this.video.rating;

                },
                error => {

                    this.router.navigateByUrl('/videos');

                }
            );

        this.http.get(`${environment.apiUrl}/video/comments?id=` + this.id)
            .subscribe(
                data => {
                    this.comments = data;
                    this.page = this.comments.meta.current_page;
                    this.comment = this.comments.data;
                    this.last = this.comments.meta.last_page;

                    if (this.last == this.page) {
                        this.end_pagination = false;
                    } else {
                        this.end_pagination = true;
                    }
                },
                error => {

                }
            );

        this.http.get(`${environment.apiUrl}/video/related?id=` + this.id)
            .subscribe(
                data => {
                    this.related_videos = data;

                },
                error => {

                }
            );

    }


    reload(id, status = false) {
        this.id = id;
        this.location.replaceState("/video/" + id);
        this.full_text = true;
        this.load_data();
        if (status) {
            this.toTop();
        }
    }
    get f() { return this.commentForm.controls; }

    onSubmit() {
        if(this.video.coming_soon){
            return;
        }
        this.submitted = true;

        if (this.commentForm.invalid) {
            return;
        }
        this.loading = true;

        this.http.post<any>(`${environment.apiUrl}/video/comment`, { id: this.id, content: this.f.comment.value }).subscribe(
            data => {
                this.alertService.success(data.message);
                this.commentForm.get('comment').setValue("");
                this.http.get(`${environment.apiUrl}/video/comments?id=` + this.id)
                    .subscribe(
                        data => {
                            this.comments = data;
                            this.page = this.comments.meta.current_page;
                            this.comment = this.comments.data;
                            this.last = this.comments.meta.last_page;
                           
                            if (this.last == this.page) {
                                this.end_pagination = false;
                            } else {
                                this.end_pagination = true;
                            }
                        },
                        error => {

                        }
                    );
                    this.loading = false;
            },
            error => {
                this.loading = false;
            }
        );

    }

    toTop() {
        window.scroll(0, 0);
    }

    play() {
        if(this.video.coming_soon){
            return;
        }
        this.playVideo = true;
        setTimeout(() => {
            var iframe = document.querySelector('#player');
            var player = new Vimeo.Player(iframe);

            player.on('ended', function () {

                if (this.video.test) {
                    this.alertService.test(this.id);

                    this.http.post(`${environment.apiUrl}/video/watched`, { id: this.id }).subscribe();
                }
            }.bind(this));

        }, 1000);
    }

    add_rating() {
        this.popup = true;
    }

    hide_popup() {
        this.popup = false;
    }

    save_rating() {
        if(this.video.coming_soon){
            return;
        }

        if (this.rating == 0) {
            return;
        }

        this.http.post<any>(`${environment.apiUrl}/video/rating`, { id: this.id, grade: this.rating }).subscribe(
            data => {
                this.alertService.success(data.message);
                this.popup = false;
                this.http.get(`${environment.apiUrl}/video/show?id=` + this.id)
                    .subscribe(
                        (data: any) => {
                            this.video = data;
                            this.video_rating = data.rating;
                            this.rating = 0;
                        }
                    );
            },
            error => {

            }
        );
    }
    full_text_togle() {
        this.full_text = !this.full_text;
    }

}
