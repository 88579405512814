import { Component, OnInit, Output, EventEmitter, ViewChild, TemplateRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService, AlertService } from '../../_services';
import { environment } from '../../../environments/environment';

import { User } from '../../_models';
import { NavbarComponent } from '../../navbar/navbar.component';
declare var $: any;

@Component({
  selector: 'app-refer-friend',
  templateUrl: './refer-friend.component.html',
  styleUrls: ['./refer-friend.component.scss']
})
export class ReferFriendComponent implements OnInit {

    sent = 0;
    clicked = 0;
    accepted = 0;
    referrals = [];
    last;
    end_pagination = false;
    page = 1;
    ready = false;
    loading = false;
    app_url;

  constructor(
    private http: HttpClient,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private userService: UserService,
    private alertService: AlertService
) { }

  ngOnInit() {
    this.loadData();
    this.app_url = window.location.origin.split("//")[1];
  }

    loadData(page = 1) {
    this.loading = true;

    this.http.get(`${environment.apiUrl}/user/referrals?page=` + page)
      .subscribe(
        (data: any) => {
          this.sent = data.data.sent;
          this.clicked = data.data.clicked;
          this.accepted = data.data.accepted;

          if(page!=1){
          this.referrals = this.referrals.concat(data.data.referrals);
          }else{
            this.referrals=data.data.referrals;
          }
          this.referrals.forEach(element => {
            element.show_more=true;
        });
          this.page = data.meta.current_page;
          this.last = data.meta.last_page;
          if (this.last == this.page) {
            this.end_pagination = false;
          }else{
            this.end_pagination=true;
          }
          this.loading = false;
        },
        error => {
            this.loading = false;
        }
      );
  }

  nextPage() {
    this.loadData(this.page+1);
  }

    resend(id, email) {
        this.router.navigate(['/invite'], { queryParams: { "email": email } });
        
    }

    referFriendLink(tab) {
        $('.nav-tabs a[href="#' + tab + '"]').tab('show');
         window.scroll(0,0);
    }

}
