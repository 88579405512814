import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { Router ,ActivatedRoute ,Params } from '@angular/router';
import { UserService, AlertService } from '../../_services';
import { environment } from '../../../environments/environment';

import { User } from '../../_models';
import { NavbarComponent } from '../../navbar/navbar.component';
declare var $: any;

@Component({
  selector: 'app-invite',
  templateUrl: './invite.component.html',
  styleUrls: ['./invite.component.scss']
})
export class InviteComponent implements OnInit {
    id;
    email;
    message = "";
    emails;
    submitted = false;
    loading = false;
    errors = new Map();
    currentUser;


  constructor(
    private http: HttpClient,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private userService: UserService,
    private alertService: AlertService
) { 
        this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    }

  ngOnInit() {
    this.route.params.subscribe((params: Params) => { this.id = params['id'] });
    
    if(this.id) {
    this.http.get(`${environment.apiUrl}/user/get-referral/` + this.id )
      .subscribe(
        (data: any) => {
          this.email = data.email;
          this.emails = data.email;

        })
    }

    this.email = this.route.snapshot.queryParamMap.get("email");
    
    this.message = this.currentUser.name + " is inviting you to join Medzel Academy! Click the link below to sign up and get access to exclusive content.";

    var invite_component = this;
    
    $.fn.multipleInput = function() {
 
          return this.each(function() {
 
               // create html elements
 
               // list of email addresses as unordered list
               var $list = $('<ul />');
 
               // input
               
               if(invite_component.email) {
                        var $input = $('<input type="text" />'); 
                          
                            $(this).val(invite_component.email);
                            var val = $(this).val();
                                    
                                        // append to list of emails with remove button
                                        $list.append($('<li class="multipleInput-email"><span>' + val + '</span></li>')
                                             .append($('<a href="#" class="multipleInput-close" title="Remove" />')
                                                  .click(function(e) {
                                                       $(this).parent().remove();
                                                       e.preventDefault();
                                                  })
                                             )
                                        );
                                        $(this).attr('placeholder', '');
                                        // empty input
                                        $(this).val(''); 
                    }

               var $input = $('<input type="text" />').keyup(function(event) {

                        var kCd = event.keyCode || event.which;
                        var code = null;
                        if (kCd == 0 || kCd == 229) { //for android chrome keycode fix
                            code = $(this).val().charCodeAt($(this).val().length - 1);
                            
                        }

                    if(event.which == 32 || event.which == 188 || event.which == 13 || code == 32 || code == 44 || code == 10 || code == 13) {
                         // key press is space or comma
                         if(event.which != 13 || (code !== null && code != 10 && code != 13)) {
                            var val = $(this).val().slice(0, -1); // remove space/comma from value
                        } else {
                            var val = $(this).val();
                        }

                         var validate_email = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test (val);
                                                        
                            if(validate_email) {
                                // append to list of emails with remove button
                                $list.append($('<li class="multipleInput-email"><span>' + val + '</span></li>')
                                     .append($('<a href="#" class="multipleInput-close" title="Remove" />')
                                          .click(function(e) {
                                               $(this).parent().remove();
                                               e.preventDefault();
                                          })
                                     )
                                );
                                $(this).attr('placeholder', '');
                                // empty input
                                $(this).val('');
                            } else {
                                $(this).val(val);
                            }
                    }
 
               });
               
 
               // container div
               var $container = $('<div class="multipleInput-container" />').click(function() {
                    $input.focus();
               });
 
               // insert elements into DOM
               $container.append($list).append($input).insertAfter($(this));
 
               // add onsubmit handler to parent form to copy emails into original input as csv before submitting
               var $orig = $(this);
               

               $("#inviteButton").click(function(e) {
                    var emails_arr = new Array();
                    $('.multipleInput-email span').each(function() {
                         emails_arr.push($(this).html());
                    });
                    emails_arr.push($input.val());
 
                    $orig.val(emails_arr.join());
                    invite_component.emails = $orig.val().replace(/(^,)|(,$)/g, "");
                    
                    invite_component.sendInvites();
              });
 
               return $(this).hide();
 
          });
 
     };
    

  }

    ngAfterViewInit() {

        $('#emailsList').multipleInput();

    }

    sendInvites() {    

    this.submitted = true;
    
    this.loading = true;

    this.http.post(`${environment.apiUrl}/user/send-referrals`, {
      emails: (this.emails) ? this.emails.split(",") : "",
      message_body: this.message
    })
      .subscribe(
        (data: any) => {
          this.alertService.success(data.message, true);
          this.loading = false;

          this.emails = "";          

            if(data.status == true) {          
                this.message = "";
                this.router.navigateByUrl('/refer-friend');
            }
        },
        error => {
          this.loading = false;
          this.errors = error;
          this.alertService.error(error.get('message'));
        }
      )

  }

}
