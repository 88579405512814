import { Component, OnInit } from '@angular/core';
import { AlertService } from '../../../_services';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-comments',
  templateUrl: './comments.component.html',
  styleUrls: ['./comments.component.scss']
})
export class CommentsComponent implements OnInit {
  comments: any = null;
  last;
  end_pagination = true;
  comments_data;
  page;
  category;
  id;
  add_comment = false;
  username_error = false;
  comment_error = false;
  errors;
  title;
  full_text=true;
  constructor(
    private alertService: AlertService,
    private router: Router,
    private http: HttpClient,
    private route: ActivatedRoute

  ) { }

  ngOnInit() {
    let currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this.title = this.route.snapshot.queryParamMap.get("name");

    if (currentUser) {
        if (currentUser.category == "Admin") {
            //this.router.navigate(['/admin/users']);
        } else {
            this.router.navigate(['/home']);
        }
    }

    this.route.params.subscribe((params: Params) => this.id = params['id']);


    this.http.get(`${environment.apiUrl}/admin/webinar/` + this.id + `/comments`)
        .subscribe(
            data => {
                this.comments = data;
                this.page = this.comments.meta.current_page;
                this.comments_data = this.comments.data;
                this.last = this.comments.meta.last_page;
                console.log(this.comments);
                this.comments_data.forEach(element => {
                    element.show_more=true;
                });
               
                console.log(this.comments_data);
                if (this.last == this.page) {
                    this.end_pagination = false;
                }
            },
            error => {
               
            }
        );

}

next_page() {
    this.page = this.page + 1;

    this.http.get(`${environment.apiUrl}/admin/webinar/` + this.id + `/comments?page=` + this.page)
        .subscribe(
            data => {
                this.comments = data;
                this.page = this.comments.meta.current_page;
                this.last = this.comments.meta.last_page;
                this.comments_data = this.comments_data.concat(this.comments.data);

                if (this.last == this.page) {
                    this.end_pagination = false;
                }
            },
            error => {
              
            }
        );
}

comment_action(id, value) {
    this.http.post(`${environment.apiUrl}/admin/comment/activate`, { id: id, active: value })
        .subscribe(
            data => {
                //let d:any =data;
                //  this.alertService.success(d.message);
            },
            error => {
                
            }
        );
}

add() {
    this.add_comment = true;
}
hide_popup() {
    this.add_comment = false;
}
full_text_togle(){
    this.full_text=!this.full_text;
}
create(u, c) {
    if (u == "") {
        this.username_error = true;
    } else {
        this.username_error = false;
    }
    if (c == "") {
        this.comment_error = true;
    } else {
        this.comment_error = false;
    }

    if (this.username_error || this.comment_error) {
        return;
    }

    this.http.post(`${environment.apiUrl}/admin/webinar/comment/add`, {
        webinar_id: this.id,
        name: u,
        content: c
    })
        .subscribe(
            data => {
                this.add_comment = false;
                let d: any = data;
                this.alertService.success(d.message);

                this.http.get(`${environment.apiUrl}/admin/webinar/` + this.id + `/comments`)
                    .subscribe(
                        data => {
                            this.comments = data;
                            this.page = this.comments.meta.current_page;
                            this.comments_data = this.comments.data;
                            this.last = this.comments.meta.last_page;
                          
                            if (this.last == this.page) {
                                this.end_pagination = false;
                            }
                        },
                        error => {
                         
                        }
                    );
            },
            error => {
             
            }
        );
}



}
