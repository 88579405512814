﻿import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';

import { AlertService, UserService } from '../_services';
import { environment } from '../../environments/environment';

@Component({
    templateUrl: 'register.component.html',
    styleUrls: ['register.component.scss']
})
export class RegisterComponent implements OnInit {
    registerForm: FormGroup;
    loading = false;
    submitted = false;
    returnUrl: string;
    list: string[] = [];
    errors = new Map();
    category: any;
    role: any='';
    checkModel;
    test;
    checkbox_errors = true;

    ref;

    constructor(
        private http: HttpClient,
        private formBuilder: FormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private userService: UserService,
        private alertService: AlertService) { }

    ngOnInit() {
        this.route.queryParams.forEach((params: Params) => {
            if (params['role']) {
                this.role = params['role'];
            }


        });
        
      this.ref = this.route.snapshot.queryParamMap.get("ref");
        if(this.ref) {
            this.http.post(`${environment.apiUrl}/referral-clicked`, {
            ref: this.ref,
          })
            .subscribe(
              (data: any) => {         
                localStorage.setItem('medzel_ref', this.ref);
              },
              error => {
              }
            )
        }

        this.registerForm = this.formBuilder.group({
            name: ['', [Validators.required, Validators.pattern('[a-zA-Z ]*')]],
            checkbox: [''],
            email: ['', Validators.required],
            password: ['', [Validators.required, Validators.minLength(6)]],
            category_id: [ this.role, Validators.required],
            client_secret: environment.clientSecret
        });

        let currentUser = JSON.parse(localStorage.getItem('currentUser'));
        if (currentUser) {
            this.router.navigate(['/home']);
        }

        // get return url from route parameters or default to '/'
        this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
        this.http.get(`${environment.apiUrl}/user-category?client_secret=` + environment.clientSecret)
            .subscribe(
                data => {
                    this.category = data;
                },
                error => {
                   
                }
            );

    }

    // convenience getter for easy access to form fields
    get f() { return this.registerForm.controls; }

    onSubmit() {
        this.submitted = true;

        let medzelRef = (localStorage.getItem('medzel_ref')) ? localStorage.getItem('medzel_ref') : this.ref;

        // stop here if form is invalid
        if (this.registerForm.invalid || this.checkbox_errors == true) {
            return;
        }

        this.loading = true;
        this.userService.register(this.registerForm.value, medzelRef)

            .subscribe(
                data => {
                    this.alertService.success(data.message, true);
                    this.loading = false;
                    delete data.message;

                    
                    if(data.referred) {
                        localStorage.removeItem('medzel_ref');
                    }

                    localStorage.setItem('currentUser', JSON.stringify(data));
                    this.router.navigate(['/profile']);
                },
                error => {

                    this.errors = error;
                    //this.alertService.error(error.get('message'));
                    this.loading = false;
                    console.log(this.errors);

                });
    }

    onChange() {

        if (this.checkbox_errors) {
            this.checkbox_errors = false;
        } else {
            this.checkbox_errors = true;
        }
    }

}
