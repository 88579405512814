﻿import { Injectable } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { Observable, Subject } from 'rxjs';
declare var $:any;
@Injectable()
export class AlertService {
    private subject = new Subject<any>();
    private comment_id = new Subject<any>();
    private comment_user = new Subject<any>();
    private comment_data = new Subject<any>();
    private keepAfterNavigationChange = false;

    constructor(private router: Router) {
        // clear alert message on route change
        router.events.subscribe(event => {
            if (event instanceof NavigationStart) {
                if (this.keepAfterNavigationChange) {
                    // only keep for a single location change
                    this.keepAfterNavigationChange = false;
                } else {
                    // clear alert
                    this.subject.next();
                }
            }
        });
    }

    success(message: string, keepAfterNavigationChange = false) {
        this.keepAfterNavigationChange = keepAfterNavigationChange;
        this.subject.next({ type: 'success', text: message });
        setTimeout(() => {
            this.subject.next();
        }, 5000);
    }

    error(message: string, keepAfterNavigationChange = false) {
        this.keepAfterNavigationChange = keepAfterNavigationChange;
        this.subject.next({ type: 'error', text: message });
        setTimeout(() => {
            this.subject.next();
        }, 5000);
    }

    video(message: string, keepAfterNavigationChange = false) {
        this.keepAfterNavigationChange = keepAfterNavigationChange;
        this.subject.next({ type: 'video', text: message });
      
    }

    test(message: string, keepAfterNavigationChange = true) {
        this.keepAfterNavigationChange = keepAfterNavigationChange;
        this.subject.next({ type: 'test', text: message });
      
    }
    comment(message) {
        this.subject.next({ type: 'comment', text: message});
      
    }

    question(message: string, keepAfterNavigationChange = false) {
        this.keepAfterNavigationChange = keepAfterNavigationChange;
        this.subject.next({ type: 'video', text: message });
      
    }

    getMessage(): Observable<any> {
        return this.subject.asObservable();
    }
    
    clear() {
        $.getScript('../../assets/main.js');
        // clear alerts
        this.subject.next();
    }
}