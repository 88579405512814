import { Component, OnInit,Input, OnChanges } from '@angular/core';
import { User } from '../_models';
import {  AuthenticationService } from '../_services';
import { Globals } from '../_helpers';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { ActivatedRoute, Router } from '@angular/router';

declare var $: any;
@Component({
  selector: 'app-logged-in-navbar',
  templateUrl: './logged-in-navbar.component.html',
  styleUrls: ['./logged-in-navbar.component.scss'],
  inputs: ['parent_data', 'pro_prop', 'user_icon', 'user_name'],
})
export class LoggedInNavbarComponent implements OnInit {
  currentUser;
  parent_data;
  token;
    pro;
    pro_prop: boolean;
    user_icon;
    user_name;

    refer_src = "../../assets/img/iconReferAFriend.svg";
    pay_src = "../../assets/img/iconPayMonthly.svg";
    divItems;
    active_item = 0;
    upgrade_popup = false;
    promo_closed: boolean;
 
  clickedEvent;

  childEventClicked(event) {
    this.clickedEvent = event;
   
  }
  constructor( private authenticationService: AuthenticationService, private http: HttpClient, private route: ActivatedRoute,
    private router: Router, private globals: Globals) { 
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this.promo_closed = globals.promo_closed;
  }

  ngOnInit() {
    this.divItems = document.getElementsByClassName("popup_rectangle");

    this.http.get(`${environment.apiUrl}/user/details`)
      .subscribe(
        (data: any) => {
          this.token = this.currentUser.token; 
          this.currentUser = data;
          this.currentUser.token = this.token;          
         
          localStorage.setItem('currentUser', JSON.stringify(this.currentUser));

        })
        this.pro = this.currentUser.pro;

  }

    ngOnChanges() {
            this.currentUser = this.parent_data;
            this.currentUser.icon = this.user_icon;
            this.currentUser.pro = this.pro_prop;
            this.currentUser.name = this.user_name;
            
  }

    

    upgradePopup() {
        $("#dropdownDiv").dropdown('toggle');
        this.upgrade_popup = true;
    }

    hide_popup() {
        this.upgrade_popup = false;

        this.refer_src = "../../assets/img/iconReferAFriend.svg";
        this.pay_src = "../../assets/img/iconPayMonthly.svg";
        this.active_item = 0;

  }

    go_to_upgrade() {
        this.router.navigate(['/payment'], { queryParams: { type: 1 } });
    }

    selected(item, active_item) {  
        this.active_item = active_item;
        this.clear();
        item.style.backgroundColor = '#DC5D63';
        if(active_item == 1) {
            this.pay_src = "../../assets/img/iconPayMonthlySelected.svg";
            this.refer_src = "../../assets/img/iconReferAFriend.svg";
        } else {
            this.refer_src = "../../assets/img/iconReferAFriendSelected.svg";
            this.pay_src = "../../assets/img/iconPayMonthly.svg";
        }
    }
    clear() {
        for(var i=0; i < this.divItems.length; i++) {
            var item = this.divItems[i];
            item.style.backgroundColor = 'white';
        }
    }

  logout(){
    this.authenticationService.logout();
  }

    promoClosed() {
        this.globals.promo_closed = true;
    }

}
