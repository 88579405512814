import { Component, OnInit } from '@angular/core';
import { AlertService } from '../../_services';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import saveAs from 'file-saver';
@Component({
    selector: 'app-videos',
    templateUrl: './videos.component.html',
    styleUrls: ['./videos.component.scss']
})
export class VideosComponent implements OnInit {

    videos: any = null;
    last;
    end_pagination = true;
    videos_data;
    page;
    category;
    role_id;

    constructor(private alertService: AlertService,
        private router: Router,
        private http: HttpClient,
        private route: ActivatedRoute) { }

    ngOnInit() {

        let currentUser = JSON.parse(localStorage.getItem('currentUser'));
        if (currentUser) {
            if (currentUser.category == "Admin") {
                //this.router.navigate(['/admin/users']);
            } else {
                this.router.navigate(['/home']);
            }
        }

        this.http.get(`${environment.apiUrl}/admin/videos`)
            .subscribe(
                data => {
                    this.videos = data;
                    this.page = this.videos.meta.current_page;
                    this.videos_data = this.videos.data;
                    this.last = this.videos.meta.last_page;

                    this.videos_data.forEach(element1 => {
                        element1.user_types.forEach(element => {
                            if (element1.user_type) {
                                element1.user_type += ', ' + element;
                            } else {
                                element1.user_type = element;
                            }
                        });
                    });


                    if (this.last == this.page) {
                        this.end_pagination = false;
                    }
                },
                error => {

                }
            );

        this.http.get(`${environment.apiUrl}/user-category?client_secret=` + environment.clientSecret)
            .subscribe(
                data => {
                    this.category = data;
                },
                error => {

                }
            );

    }

    next_page() {
        this.page = this.page + 1;
        let url;
        if (this.role_id) {
            url = `${environment.apiUrl}/admin/videos?page=` + this.page + "&role_id=" + this.role_id;
        } else {
            url = `${environment.apiUrl}/admin/videos?page=` + this.page;
        }
        this.http.get(url)
            .subscribe(
                data => {
                    this.videos = data;
                    this.page = this.videos.meta.current_page;
                    this.last = this.videos.meta.last_page;
                    this.videos_data = this.videos_data.concat(this.videos.data);

                    this.videos_data.forEach(element1 => {
                        element1.user_type="";
                        element1.user_types.forEach(element => {
                            if (element1.user_type) {
                                element1.user_type += ', ' + element;
                            } else {
                                element1.user_type = element;
                            }
                        });
                    });

                    if (this.last == this.page) {
                        this.end_pagination = false;
                    } else {
                        this.end_pagination = true;
                    }
                },
                error => {

                }
            );
    }
    reloadVideos() {
        this.role_id = 0;
        this.http.get(`${environment.apiUrl}/admin/videos`)
            .subscribe(
                data => {
                    this.videos = data;
                    this.page = this.videos.meta.current_page;
                    this.videos_data = this.videos.data;
                    this.last = this.videos.meta.last_page;

                    if (this.last == this.page) {
                        this.end_pagination = false;
                    } else {
                        this.end_pagination = true;
                    }
                },
                error => {

                }
            );
    }
    filter(id) {
        this.role_id = id;
        this.http.get(`${environment.apiUrl}/admin/videos?role_id=` + id)
            .subscribe(
                data => {
                    this.videos = data;
                    this.page = this.videos.meta.current_page;
                    this.videos_data = this.videos.data;
                    this.last = this.videos.meta.last_page;

                    if (this.last == this.page) {
                        this.end_pagination = false;
                    }
                },
                error => {

                }
            );
    }
    view(url) {
        this.router.navigate(['/admin/comments/' + url]);
    }
    edit(id) {
        this.router.navigate(['/admin/videos/' + id + `/edit`]);
    }

}
