import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup,Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService, AlertService } from '../_services';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent implements OnInit {
  loginForm: FormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;
  errors = new Map();
  constructor( private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private http: HttpClient,
    private authenticationService: AuthenticationService,
    private alertService: AlertService) { }

  ngOnInit() {

    this.loginForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      name: ['', Validators.required],
      message_content: ['', Validators.required],
      client_secret: environment.clientSecret
  });
  }
  get f() { return this.loginForm.controls; }

  onSubmit() {
      this.submitted = true;
    
      // stop here if form is invalid
      if (this.loginForm.invalid) {
          return;
      }
      this.loading = true; 

      return this.http.post<any>(`${environment.apiUrl}/contact`, {
        name: this.f.name.value,
        email:this.f.email.value,
        message_content:this.f.message_content.value      
      }) .subscribe(               
        data => { 
          this.alertService.success(data.message, true);
          this.loading = false; 
        },
        error => {
          this.errors=error;
          this.alertService.error(error.get('message'));
          this.loading = false; 
        }
    );
     
      
  }
}
