import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { UserService, AlertService } from '../../../_services';
import { environment } from '../../../../environments/environment';
import { Router ,ActivatedRoute ,Params } from '@angular/router';

import { User } from '../../../_models';
import { NavbarComponent } from '../../../navbar/navbar.component';

@Component({
  selector: 'app-resend',
  templateUrl: './resend.component.html',
  styleUrls: ['./resend.component.scss']
})
export class ResendComponent implements OnInit {
    id;
    email;

  constructor(
    private http: HttpClient,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private userService: UserService,
    private alertService: AlertService
) { }

  ngOnInit() {
    this.route.params.subscribe((params: Params) => { this.id = params['id'] });
    this.http.get(`${environment.apiUrl}/user/get-referral/` + this.id )
      .subscribe(
        (data: any) => {
          this.email = data.email;

        })
  }

}
