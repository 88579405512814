import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../../_services';
import { Router } from '@angular/router';
declare var $: any;

@Component({
  selector: 'app-user-sidebar',
  templateUrl: './user-sidebar.component.html',
  styleUrls: ['./user-sidebar.component.scss']
})
export class UserSidebarComponent implements OnInit {

  id=1;
  constructor(private authenticationService: AuthenticationService,
    private router: Router
  ) { }

  ngOnInit() {
        
    if(this.router.url.includes('profile')){
      this.id=1;
    }
    if(this.router.url.includes('documents')){
      this.id=2;
    }
    if(this.router.url.includes('certificates')){
      this.id=3;
    }
    if(this.router.url.includes('change-password')){
      this.id=4;
    }
    if(this.router.url.includes('refer-friend')){
      this.id=5;
    }
    if(this.router.url.includes('billing-info')){
      this.id=6;
    }
    if(this.router.url.includes('invoices')){
      this.id=7;
    }
  }
    addClass(id){   
  }

}

