﻿import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { User } from '../_models';

@Injectable()
export class UserService {
    constructor(private http: HttpClient) { }

    getAll() {
        return this.http.get<User[]>(`${environment.apiUrl}/users`);
    }

    getById(id: number) {
        return this.http.get(`${environment.apiUrl}/users/` + id);
    }

    /*register(user: User) {
        return this.http.post(`${environment.apiUrl}/register`, user);
    }*/

    register(user: User, ref) {
        return this.http.post<any>(`${environment.apiUrl}/register`, {user: user, ref: ref})
            .pipe(map(user => {
                // login successful if there's a jwt token in the response
                if (user && user.token) {
                    // store user details and jwt token in local storage to keep user logged in between page refreshes
                    localStorage.setItem('currentUser', JSON.stringify(user));
                }

                return user;
            }));
    }
    reset(email) {
        return this.http.post<any>(`${environment.apiUrl}/password-forgot`, {email:email,client_secret: environment.clientSecret});
    }
    resetPassword(email,password,token) {
        return this.http.post<any>(`${environment.apiUrl}/password-reset`, {
            email:email,
            client_secret: environment.clientSecret,
            password:password,
            token:token
        });
    }

    activate(token) {
        return this.http.post<any>(`${environment.apiUrl}/activate`, {activate_token:token,client_secret: environment.clientSecret});
    }

    update(form) {
        return this.http.post<any>(`${environment.apiUrl}/user/edit`, {name:form.name.value,
grad_date:form.grad_date.value});
    }
    reactivate() {
        return this.http.post<any>(`${environment.apiUrl}/user/resend-activation`, {});
    }
    avatar(name) {
        return this.http.post<any>(`${environment.apiUrl}/user/edit-avatar`, name);
    }
    file(name) {
        return this.http.post<any>(`${environment.apiUrl}/user/edit-document`, name);
    }
    delete(id: number) {
        return this.http.delete(`${environment.apiUrl}/users/` + id);
    }
}