﻿import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { AlertService, AuthenticationService } from '../_services';

@Component({
    templateUrl: 'login.component.html',
    styleUrls: ['login.component.scss']
})
export class LoginComponent implements OnInit {
    loginForm: FormGroup;
    loading = false;
    submitted = false;
    returnUrl: string;

    constructor(
        private formBuilder: FormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private authenticationService: AuthenticationService,
        private alertService: AlertService) { }

    ngOnInit() {
        this.loginForm = this.formBuilder.group({
            email: ['', [Validators.required, Validators.email]],
            password: ['', Validators.required],
            client_secret: environment.clientSecret
        });

        let currentUser = JSON.parse(localStorage.getItem('currentUser'));
        if (currentUser) {
            if(currentUser.category=="Admin"){
                this.router.navigate(['/admin/users']);
            }else{
            this.router.navigate(['/home']);
            }
        }

        // get return url from route parameters or default to '/'
        
        this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/profile';
console.log(this.returnUrl)
    }

    // convenience getter for easy access to form fields
    get f() { return this.loginForm.controls; }

    onSubmit() {
        this.submitted = true;

        // stop here if form is invalid
        if (this.loginForm.invalid) {
            return;
        }

        this.loading = true;
        this.authenticationService.login(this.f.email.value, this.f.password.value)
            .pipe(first())
            .subscribe(
                data => {
                    if(data.category=="Admin"){
                        this.router.navigate(['/admin/users']);
                    }else{                        
                            this.router.navigateByUrl(this.returnUrl);
                    }
                    if (data.icon === null) {
                        data.icon = "../../assets/img/profile1.jpg";
                    }
                    if (data.thumbnail === null) {
                        data.thumbnail = "../../assets/img/profile1.jpg"
                    }
                    localStorage.setItem('currentUser', JSON.stringify(data));
                    
                },
                error => {
                    this.alertService.error(error.get('message'));
                    this.loading = false;
                });
    }

}
