import { NgModule }      from '@angular/core';
import { BrowserModule , HAMMER_GESTURE_CONFIG, HammerGestureConfig  } from '@angular/platform-browser';
import { ReactiveFormsModule }    from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS ,HttpClientJsonpModule} from '@angular/common/http';
import { StarRatingModule } from 'angular-star-rating';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { FormsModule } from '@angular/forms';
//import { fakeBackendProvider } from './_helpers';
import { SlickModule } from 'ngx-slick';
import { AppComponent }  from './app.component';
import { routing }        from './app.routing';
import { AnimateInModule } from 'ngx-animate-in';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';


import { AlertComponent } from './_directives';
import { AuthGuard } from './_guards';
import { AdminGuard } from './admin.guard';
import { JwtInterceptor, ErrorInterceptor, Globals } from './_helpers';
import { AlertService, AuthenticationService, UserService } from './_services';
import { HomeComponent } from './home';
import { LoginComponent } from './login';
import { RegisterComponent } from './register';
import { ResetComponent } from './reset/reset.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { ActivateComponent } from './activate/activate.component';
import { IndexComponent } from './index/index.component';
import { NavbarComponent } from './navbar/navbar.component';
import { FooterComponent } from './footer/footer.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { WhatWeDoComponent } from './what-we-do/what-we-do.component';
import { UserProfileComponent } from './profile/user-profile/user-profile.component';
import { LoggedInNavbarComponent } from './logged-in-navbar/logged-in-navbar.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { TermsComponent } from './terms/terms.component';
import { VideoComponent } from './video/video.component';
import { SafePipe } from './safe.pipe';
import { ChangePasswordComponent } from './profile/change-password/change-password.component';
import { TestComponent } from './test/test.component';
import { SurveyComponent } from './survey/survey.component';
import { CertificatesComponent } from './profile/certificates/certificates.component';
import { UsersComponent } from './admin/users/users.component';
import { AdminNavbarComponent } from './admin-navbar/admin-navbar.component';
import { VideosComponent } from './admin/videos/videos.component';
import { CommentsComponent } from './admin/comments/comments.component';
import { IndexComponent as SpeakerIndex } from './admin/speaker/index/index.component';
import { EditSpeakerComponent } from './admin/speaker/edit-speaker/edit-speaker.component';
import { AddSpeakerComponent } from './admin/speaker/add-speaker/add-speaker.component';
import { CreateComponent } from './admin/videos/create/create.component';
import { EditComponent } from './admin/videos/edit/edit.component';
import { CategoriesComponent } from './admin/categories/categories.component';
import { SurveyComponent as AdminSurvey } from './admin/survey/survey.component';
import { PasswordComponent } from './admin/password/password.component';
import { ParallaxModule, ParallaxConfig } from 'ngx-parallax';
import { CopyrightComponent } from './copyright/copyright.component';
import { TestSurveyComponent } from './test-survey/test-survey.component';
import { WebinarsComponent } from './webinars/webinars.component';
import { WebinarsComponent  as AdminWebinar } from './admin/webinars/webinars.component';
import { UsersComponent  as WebinarUsers } from './admin/webinars/users/users.component';
import { CreateComponent  as CreateWebinar } from './admin/webinars/create/create.component';
import { EditComponent  as EditWebinar } from './admin/webinars/edit/edit.component';
import { UserSidebarComponent } from './profile/user-sidebar/user-sidebar.component';
import { DocumentsComponent } from './profile/documents/documents.component';
import { ReferFriendComponent } from './profile/refer-friend/refer-friend.component';
import { BillingInfoComponent } from './profile/billing-info/billing-info.component';
import { InvoicesComponent } from './profile/invoices/invoices.component';
import { InviteComponent } from './profile/invite/invite.component';
import { UpgradeComponent } from './profile/upgrade/upgrade.component';
import { ResendComponent } from './profile/invite/resend/resend.component';
import { PricingComponent } from './profile/pricing/pricing.component';
import { CommentsComponent as AdminWebinarComments } from './admin/webinars/comments/comments.component';
import { DetailsComponent } from './webinars/details/details.component';
import { StatusComponent } from './status/status.component';

export class MyHammerConfig extends HammerGestureConfig {
    overrides = <any> {
        'pinch': { enable: false },
        'rotate': { enable: false }
    }
}

@NgModule({
    imports: [
        BrowserModule,
        ReactiveFormsModule,
        HttpClientModule,
        HttpClientJsonpModule,
        routing,
        FormsModule,
        AngularEditorModule,
        StarRatingModule.forRoot(),
        SlickModule.forRoot(),
        AnimateInModule,
        BrowserAnimationsModule,
        ParallaxModule,
        NgbModule,
        CalendarModule.forRoot({
            provide: DateAdapter,
            useFactory: adapterFactory
          })
        
       
        
    ],
    declarations: [
        AppComponent,
        AlertComponent,
        HomeComponent,
        LoginComponent,
        RegisterComponent,
        ResetComponent,
        ResetPasswordComponent,
        ActivateComponent,
        IndexComponent,
        NavbarComponent,
        FooterComponent,
        ContactUsComponent,
        AboutUsComponent,
        WhatWeDoComponent,
        UserProfileComponent,
        LoggedInNavbarComponent,
        PrivacyComponent,
        TermsComponent,
        VideoComponent,
        SafePipe,
        ChangePasswordComponent,
        TestComponent,
        SurveyComponent,
        CertificatesComponent,
        UsersComponent,
        AdminNavbarComponent,
        VideosComponent,
        CommentsComponent,
        SpeakerIndex,
        EditSpeakerComponent,
        AddSpeakerComponent,
        CreateComponent,
        EditComponent,
        CategoriesComponent,
        AdminSurvey,
        PasswordComponent,
        CopyrightComponent,
        TestSurveyComponent,
        WebinarsComponent,
        AdminWebinar,
        WebinarUsers,
        CreateWebinar,
        EditWebinar,
        UserSidebarComponent,
        DocumentsComponent,
        ReferFriendComponent,
        BillingInfoComponent,
        InvoicesComponent,
        InviteComponent,
        UpgradeComponent,
        ResendComponent,
        PricingComponent,
        AdminWebinarComments,
        DetailsComponent,
        StatusComponent
      
    ],
    providers: [
        AuthGuard,
        AdminGuard,
        AlertService,
        AuthenticationService,
        UserService,
        Globals,
        { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
        {
            provide: HAMMER_GESTURE_CONFIG,
            useClass: MyHammerConfig
        }
        // provider used to create fake backend
        //fakeBackendProvider
    ],
    bootstrap: [AppComponent]
})

export class AppModule { }