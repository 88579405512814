import { Component, OnInit, Output, EventEmitter, ViewChild, TemplateRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService, AlertService } from '../../_services';
import { environment } from '../../../environments/environment';

import { User } from '../../_models';
import { NavbarComponent } from '../../navbar/navbar.component';

@Component({
  selector: 'app-invoices',
  templateUrl: './invoices.component.html',
  styleUrls: ['./invoices.component.scss']
})
export class InvoicesComponent implements OnInit {

    invoices = [];
    last;
    end_pagination = false;
    page = 1;
    ready = false;
    loading = false;

  constructor(
    private http: HttpClient,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private userService: UserService,
    private alertService: AlertService
  ) { }

  ngOnInit() {
    this.loadData();
  }

    loadData(page = 1) {
    this.loading = true;

    this.http.get(`${environment.apiUrl}/user/invoices?page=` + page)
      .subscribe(
        (data: any) => {

          if(page!=1){          
          let new_data = Object.keys(data.data).map(key => {
                return data.data[key];
            });
            this.invoices = this.invoices.concat(new_data);
          
          }else{
            this.invoices=(data.data) ? data.data : [];
          }
          
          this.page = data.current_page;
          this.last = data.last_page;
          if (this.last == this.page) {
            this.end_pagination = false;
          }else{
            this.end_pagination=true;
          }
          this.loading = false;
        },
        error => {
            this.loading = false;
        }
      );
  }

  nextPage() {
    this.loadData(this.page+1);
  }

    pay(id, number, description, date, total) {
        this.router.navigate(['/payment'],
        { queryParams:
            {
                type: 4,
                id: id,
                number: number,
                name: description,
                time: date,
                price: total
            } 
        });
    }

}
