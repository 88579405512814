import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { DomSanitizer } from '@angular/platform-browser';
import { AlertService } from '../../../_services';


@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit {

  constructor(private router: Router,
    private route: ActivatedRoute,
    private alertService: AlertService,
    private http: HttpClient,
    private sanitizer: DomSanitizer) { }
  id;
  name;
  users;
  page = 1;
  last;
  end_pagination = false;
  fileUrl;
  email_subject;
  email_body;
  email_popup = false;
  email_popup1 = false;
  content_error;
  subject_error;
  email_progress = false;
  email_draft;
  subject;
  content;
  subject1;
  content1;
  user_id;
  all = false;


  ngOnInit() {
    this.route.params.subscribe((params: Params) => { this.id = params['id'] });
    this.route.queryParams.subscribe((params: Params) => { this.name = params['name'] });

    this.load_data();
    this.load_draft();
  }

  load_data(page = 1) {
    this.http.get(`${environment.apiUrl}/admin/webinar/` + this.id + `/users?page=` + page)
      .subscribe(
        (data: any) => {
          if (page != 1) {
            this.users = this.users.concat(data.data);
          } else {
            this.users = data.data;
          }
          this.page = data.meta.current_page;
          this.last = data.meta.last_page;
          if (this.last == this.page) {
            this.end_pagination = false;
          } else {
            this.end_pagination = true;
          }
        },
        error => {

        }
      );
  }
  load_draft() {
    this.http.get(`${environment.apiUrl}/admin/webinar/${this.id}/draft`)
      .subscribe(
        (data: any) => {
          this.email_draft = data;
          this.subject = this.email_draft.subject
          this.content = this.email_draft.message
        }
      );
  }

  next_page() {
    this.load_data(this.page + 1);
  }
  download() {
    this.http.get(environment.apiUrl + "/admin/webinar/" + this.id + "/emails-list")
      .subscribe(
        (data: any) => {
          window.open(data.link, '_self');
        })
  }
  toogle_popup() {
    this.email_popup = !this.email_popup;
  }
  toogle_popup1(id) {
    this.email_popup1 = !this.email_popup1;
    this.user_id = id;
    this.subject1 = this.subject;
    this.content1 = this.content;
  }
  send_email(type, subject, content) {
    if (subject == "") {
      this.subject_error = true;
    } else {
      this.subject_error = false;
    }
    if (content == "") {
      this.content_error = true;
    } else {
      this.content_error = false;
    }

    if (this.subject_error || this.content_error) {
      return;
    }
    if (type) {
      if (confirm("Are you sure you want to send this email?")) {
        this.email_progress = true;
        this.http.post(`${environment.apiUrl}/admin/webinar/send-emails`, {
          subject: subject,
          message: content,
          send: type,
          bulk: 1,
          all: this.all ? 0 : 1,
          id: this.id
        })
          .subscribe(
            (data: any) => {
              this.email_progress = false;
              this.email_popup = false;
              this.alertService.success(data.message);
              this.load_data();
            },
            error => {
              this.email_progress = false;
            })
      }
    }
    else {
      this.email_progress = true;
      this.http.post(`${environment.apiUrl}/admin/webinar/send-emails`, {
        subject: subject,
        message: content,
        send: type,
        bulk: 1,
        all: this.all ? 0 : 1,
        id: this.id
      })
        .subscribe(
          (data: any) => {
            this.email_progress = false;
            this.email_popup = false;
            this.alertService.success(data.message);
            this.load_data();
          },
          error => {
            this.email_progress = false;
          })
    }

  }
  send_individual_email() {
    if (this.subject1 == "") {
      this.subject_error = true;
    } else {
      this.subject_error = false;
    }
    if (this.content1 == "") {
      this.content_error = true;
    } else {
      this.content_error = false;
    }

    if (this.subject_error || this.content_error) {
      return;
    }

    if (confirm("Are you sure you want to send this email?")) {
      this.email_progress = true;
      this.http.post(`${environment.apiUrl}/admin/webinar/send-individual`, {
        subject: this.subject1,
        message: this.content1,
        user_id: this.user_id,
        id: this.id
      })
        .subscribe(
          (data: any) => {
            this.email_progress = false;
            this.email_popup1 = false;
            this.alertService.success(data.message);
            this.load_data();
          },
          error => {
            this.email_progress = false;
          })
    }

  }


}
