import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertService } from '../_services';
import { environment } from '../../environments/environment';



@Component({
  selector: 'app-test-survey',
  templateUrl: './test-survey.component.html',
  styleUrls: ['./test-survey.component.scss']
})
export class TestSurveyComponent implements OnInit {

  survey: FormGroup;
  errors = new Map();

  constructor(
    private http: HttpClient,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private alertService: AlertService
  ) { }

  ngOnInit() {

    this.survey = this.formBuilder.group({

      first_name: ['', Validators.required],
      middle_name: [''],
      last_name: ['', Validators.required],
      number: ['', Validators.required],
      email: ['', Validators.required],
      profession: ['', Validators.required],
      specialty: ['', Validators.required],
      hours: ['', Validators.required],
      minutes: ['', Validators.required],
      date: ['', Validators.required],
      faculty_1: ['', Validators.required],
      faculty_2: ['', Validators.required],
      faculty_3: ['', Validators.required],
      balance_1: ['', Validators.required],
      balance_2: ['', Validators.required],
      explain_1: [''],
      overall_1: ['', Validators.required],
      overall_2: ['', Validators.required],
      overall_3: ['', Validators.required],
      explain_2: [''],
      question_1: ['', Validators.required],
      question_2: ['', Validators.required],
      comments: [''],

    });

  }

  get f() { return this.survey.controls; }


  onSubmit() {
    console.log();
    this.http.post(`${environment.apiUrl}/survey/form`, {
      first_name: this.f.first_name.value,
      middle_name: this.f.middle_name.value,
      last_name: this.f.last_name.value,
      number: this.f.number.value,
      email: this.f.email.value,
      profession: this.f.profession.value,
      specialty: this.f.specialty.value,
      hours: this.f.hours.value,
      minutes: this.f.minutes.value,
      date: this.f.date.value,
      faculty_1: this.f.faculty_1.value,
      faculty_2: this.f.faculty_2.value,
      faculty_3: this.f.faculty_3.value,
      balance_1: this.f.balance_1.value,
      balance_2: this.f.balance_2.value,
      explain_1: this.f.explain_1.value,
      overall_1: this.f.overall_1.value,
      overall_2: this.f.overall_2.value,
      overall_3: this.f.overall_3.value,
      explain_2: this.f.explain_2.value,
      question_1: this.f.question_1.value,
      question_2: this.f.question_2.value,
      comments: this.f.comments.value,

    })
      .subscribe(
        (data: any) => {
          this.alertService.success(data.message, true);
          this.router.navigate(['/home']);
        },
        error => {
          this.errors = error;
        }
      );
  }

}
