﻿import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';

import { environment } from '../../environments/environment';

@Injectable()
export class AuthenticationService {
    constructor(private http: HttpClient,
              private router: Router
    ) { }

    login(email: string, password: string) {
        return this.http.post<any>(`${environment.apiUrl}/login`, { email: email, password: password ,client_secret: environment.clientSecret})
            .pipe(map(user => {
                // login successful if there's a jwt token in the response
                if (user && user.token) {
                    // store user details and jwt token in local storage to keep user logged in between page refreshes
                   
                }

                return user;
            }));
    }

    logout() {
        // remove user from local storage to log user out
        localStorage.removeItem('currentUser');
        this.router.navigate(['/login']);
        //return this.http.post<any>(`${environment.apiUrl}/logout`, {})
        //.subscribe(
         //   data => {                
              
          //  },
          //  error => {
          //      console.log(error);
          //  }
       // );
    }
}