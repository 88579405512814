import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { AlertService } from '../../_services';
import { environment } from '../../../environments/environment';
import { trigger, transition, useAnimation } from '@angular/animations';
import { fadeIn } from 'ngx-animate';
import { style, animate, keyframes, query, stagger } from '@angular/animations';
import { Location } from '@angular/common';

@Component({
    selector: 'app-details',
    templateUrl: './details.component.html',
    styleUrls: ['./details.component.scss'],
    animations: [
        trigger('bounce', [transition('* => *', useAnimation(fadeIn))]),


        trigger('listAnimation', [
            transition('* => *', [

                query(':enter', style({ opacity: 0 }), { optional: true }),

                query(':enter', stagger('300ms', [
                    animate('0.8s ease-in', keyframes([
                        style({ opacity: 0, transform: 'translateY(-20px)', offset: 0 }),
                        style({ opacity: .5, transform: 'translateY(20px)', offset: 0.4 }),
                        style({ opacity: 1, transform: 'translateY(0)', offset: 0.8 }),
                    ]))]), { optional: true })
            ])
        ])

    ]

})
export class DetailsComponent implements OnInit {

    webinar;
    upcoming;
    id;
    user;
    submitted;
    comments: any = null;
    comment: any = null;
    page;
    last;
    end_pagination = true;
    commentForm: FormGroup;
    registerName = '';
    registerTime = '';
    registerId = '';
    registerPrice;
    pay;
    popup;
    popup_type;
    premium;
    currentUser;
    loading = false;
    plan_id;
    progres;

    constructor(
        private router: Router,
        private http: HttpClient,
        private formBuilder: FormBuilder,
        private route: ActivatedRoute,
        private location: Location,
        private alertService: AlertService,

    ) {
        this.currentUser = JSON.parse(localStorage.getItem('currentUser'));

     }

    ngOnInit() {
        this.route.params.subscribe((params: Params) => this.id = params['id']);
        this.load_data();
        this.user = JSON.parse(localStorage.getItem('currentUser'));
        this.plan_id = this.currentUser.plan_id;
        this.premium = this.plan_id == 3;
    
        this.commentForm = this.formBuilder.group({
            comment: ['', Validators.required]
        });
    }
    goTo(id) {
        this.router.navigate(['/webinars/' + id], { queryParams: { id: id } });

    }
    reload(id, status = false) {
        this.id = id;
        this.location.replaceState("/video/" + id);
        this.load_data();
        if (status) {
            this.toTop();
        }

    }
    toTop() {
        window.scroll(0, 0);
    }

    next_comment_page() {
        this.page = this.page + 1;
        this.http.get(`${environment.apiUrl}/webinar/comments?page=` + this.page + `&id=` + this.id)
            .subscribe(
                data => {
                    this.comments = data;
                    this.page = this.comments.meta.current_page;
                    this.last = this.comments.meta.last_page;
                    this.comment = this.comment.concat(this.comments.data);

                    if (this.last == this.page) {
                        this.end_pagination = false;
                    }
                },
                error => {

                }
            );
    }
    get f() { return this.commentForm.controls; }

    load_data() {
        this.http.get(`${environment.apiUrl}/webinar-details?id=` + this.id)
            .subscribe(
                data => {
                    this.webinar = data;

                },
                error => {

                    this.router.navigateByUrl('/webinars');

                }
            );

        this.http.get(`${environment.apiUrl}/webinar-details/upcoming-webinars`)
            .subscribe(
                data => {
                    this.upcoming = data["data"]
                    console.log(this.upcoming)
                },
                error => {

                }
            );
        this.http.get(`${environment.apiUrl}/webinar/comments?id=` + this.id)
            .subscribe(
                data => {
                    this.comments = data;
                    this.page = this.comments.meta.current_page;
                    this.comment = this.comments.data;
                    this.last = this.comments.meta.last_page;

                    if (this.last == this.page) {
                        this.end_pagination = false;
                    } else {
                        this.end_pagination = true;
                    }
                },
                error => {

                }
            );


    }
    register(id, name, time, pay, price, registered) {
        if (!registered) {
            this.registerId = id;
            this.registerName = name;
            this.registerTime = time;
            this.pay = pay;
            this.popup_type = (pay == false || this.premium) ? 1 : 2;
            this.registerPrice = price;
            this.popup = true;
        }
    }
    pay_webinar() {
        this.router.navigate(['/payment'],
          {
            queryParams:
            {
              type: 2,
              id: this.registerId,
              name: this.registerName,
              time: this.registerTime,
              price: this.registerPrice
            }
          });
      }
      hide_popup() {
        this.popup = false;
      }
    
      save_registration() {
        this.http.post<any>(`${environment.apiUrl}/webinar/register`, { id: this.registerId }).subscribe(
          data => {
            this.popup = false;
       
            this.alertService.success(data.message);
            this.progres = false;
            this.reload(this.webinar.id)
          },
          error => {
            this.progres = false;
          })
    
    
      }
    onSubmit() {
        this.submitted = true;

        if (this.commentForm.invalid) {
            return;
        }
        this.loading = true;

        this.http.post<any>(`${environment.apiUrl}/webinar/comment`, { id: this.id, content: this.f.comment.value }).subscribe(
            data => {
                this.alertService.success(data.message);
                this.commentForm.get('comment').setValue("");
                this.http.get(`${environment.apiUrl}/webinar/comments?id=` + this.id)
                    .subscribe(
                        data => {
                            this.comments = data;
                            this.page = this.comments.meta.current_page;
                            this.comment = this.comments.data;
                            this.last = this.comments.meta.last_page;

                            if (this.last == this.page) {
                                this.end_pagination = false;
                            } else {
                                this.end_pagination = true;
                            }
                        },
                        error => {

                        }
                    );
                this.loading = false;
            },
            error => {
                this.loading = false;
            }
        );

    }

}
