import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService, AlertService } from '../../_services';
import { environment } from '../../../environments/environment';

import { User } from '../../_models';
import { NavbarComponent } from '../../navbar/navbar.component';
declare var $: any;

@Component({
  selector: 'app-documents',
  templateUrl: './documents.component.html',
  styleUrls: ['./documents.component.scss']
})
export class DocumentsComponent implements OnInit {
    errors = new Map();
    updateProfileDocs: FormGroup;
    loading = false;
    submitted = false;
    currentUser;
    file;
    display_msg=false;
    doc_name;
    parentInput;
    file_uploading=false;

  constructor(
    private http: HttpClient,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private userService: UserService,
    private alertService: AlertService
) {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this.parentInput=this.currentUser;    
 }
    @Output() eventClicked = new EventEmitter<Event>();

  ngOnInit() {
    this.updateProfileDocs = this.formBuilder.group({
      file: ['']
    });

    
    if(this.currentUser.document){
        var parts = this.currentUser.document;
        this.doc_name = parts.substr(parts.lastIndexOf('/') + 1);
        var i = this.doc_name.indexOf('_');
        this.doc_name = this.doc_name.slice(i+1);
    }
  }
    trigerFileUpload() {
    $("#fileupload:hidden").trigger('click');
  }

    fileChanged(event) {
    let file = event.target.files[0];
    if(file.size/1000000>2){
      this.alertService.error("Practitioner Document must be under 2MB");
      return;
    }
    const fd = new FormData();
    fd.append('document', file, file.name);
    this.file_uploading=true;
    this.userService.file(fd)
      .subscribe(
        data => {
          this.alertService.success(data.message, true);
          this.currentUser.document =data.document;
          localStorage.setItem('currentUser', JSON.stringify(this.currentUser));

          var parts = this.currentUser.document;
          this.doc_name = parts.substr(parts.lastIndexOf('/') + 1);
          var i = this.doc_name.indexOf('_');
          this.doc_name = this.doc_name.slice(i+1);

          this.eventClicked.emit(this.currentUser.document);
          this.file_uploading=false;
        },
        error => {
          this.errors = error;
          this.alertService.error(error.get('message'));
          this.loading = false;
          this.file_uploading=false;
        });
  }

    diplayMsg(){
      if(this.display_msg){
        this.display_msg=false;
      }else{
        this.display_msg=true;
      }
      
  }


}
