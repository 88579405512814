import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService, AlertService } from '../../_services';
import { environment } from '../../../environments/environment';

import { User } from '../../_models';
import { NavbarComponent } from '../../navbar/navbar.component';

@Component({
  selector: 'app-upgrade',
  templateUrl: './upgrade.component.html',
  styleUrls: ['./upgrade.component.scss']
})
export class UpgradeComponent implements OnInit {

  currentUser;
  type;
  stripe;
  pro;
  name = "";
  time = "";
  price = "";
  title = "";
  invoice_number = "";
  id = "";
  annually;
  premium;

  card_number;
  expiry_date;
  cvv;

  card_last4;
  existing = 0;
  pro_to_premium=false;
  to_pro;

  loading = false;
  form_submitted = false;
  errors = new Map();

  card_number_place = "";
  expiry_date_place = "";
  cvv_place = "";

  promo_code = "";
  promo_code_id = "";
  price_promo = "";
  new_price = "";
  loading_promo = false;
  promo_submitted = false;
  percentage_off = "";
  promo_applied = false;
  amount_off = "";
  coupon_off = "";

  constructor(
    private http: HttpClient,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private userService: UserService,
    private alertService: AlertService
  ) {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
  }

  ngOnInit() {

    this.stripe = this.currentUser.stripe;
    this.pro = this.currentUser.pro;

    this.type = this.route.snapshot.queryParamMap.get("type");
    this.annually = !!Number(this.route.snapshot.queryParamMap.get("annualy"));
    this.premium = !!Number(this.route.snapshot.queryParamMap.get("premium"));
    this.to_pro = !!Number(this.route.snapshot.queryParamMap.get("pro"));

console.log(this.premium)
    if (this.stripe) {
      this.existing = 1;
      this.card_last4 = this.currentUser.card_last4

      this.card_number_place = "**** **** **** " + this.card_last4;
      this.expiry_date_place = "**/****";
      this.cvv_place = "***";
    } else {
      this.card_number_place = "Your card number";
      this.expiry_date_place = "MM/YYYY";
      this.cvv_place = "Your card cvv";
    }

    if (this.type == 1) {
      let isPremium = this.premium ? 'PREMIUM' : 'PRO'
      this.title = "Upgrade to " + isPremium;
      let tmp = this.annually == 1 ? 'Annually' : "Monthly";
      this.name = tmp + " Subscription to " + isPremium + " plan";
      this.price = this.annually == 1 ? '99' : '19.99';
      if (this.premium) {
        this.price = this.annually == 1 ? '199' : '29.99';
         if(this.pro){
           this.pro_to_premium=true
           this.price = this.annually == 1 ? '99' : '19.99';
         }

        /*
         this.price = this.annually == 1 ? '100' : '10';
         }else{
         this.price = this.annually == 1 ? '199' : '29.99';
         }
         */
      }
    } else if (this.type == 2) {
      this.title = "Webinar Registration";
      this.id = this.route.snapshot.queryParamMap.get("id");
      this.name = this.route.snapshot.queryParamMap.get("name");
      this.time = this.route.snapshot.queryParamMap.get("time");
      this.price = this.route.snapshot.queryParamMap.get("price");
    } else if (this.type == 3) {
      this.title = "Certificate Print";
      this.id = this.route.snapshot.queryParamMap.get("id");
      this.name = this.route.snapshot.queryParamMap.get("name");
      if (this.pro) {
        this.price = '4.99';
      } else if (this.currentUser.plan_id == 3) {
        this.price = '';
      } else {
        this.price = '9.99'
      }
      //this.price = this.route.snapshot.queryParamMap.get("price");
    } else if (this.type == 4) {
      this.title = "Invoice";
      this.id = this.route.snapshot.queryParamMap.get("id");
      this.name = this.route.snapshot.queryParamMap.get("name");
      this.time = this.route.snapshot.queryParamMap.get("time");
      this.price = this.route.snapshot.queryParamMap.get("price");
      this.invoice_number = this.route.snapshot.queryParamMap.get("number");
    }
  }

  onKey(event: any) { // without type info

    var key = event.keyCode || event.charCode;

    if (key != 8) {
      if (event.target.value.length === 2) {
        event.target.value += '/';
      }
    }
  }

  applyPromo() {
    if (this.type == 1) {
      this.promo_submitted = true;
      this.loading_promo = true;

      this.http.post(`${environment.apiUrl + '/subscription/apply-promotion'}`, {
        promo_code: this.promo_code,
        annually: this.annually,
        premium: this.premium,
        price: this.price
      })
        .subscribe(
          (data: any) => {
            this.loading_promo = false;
            if (data.valid) {
              this.promo_applied = true;
              this.price = data.new_price;
              this.percentage_off = data.percentage_off;
              this.promo_code_id = data.promo_code_id;
              this.amount_off = data.amount_off;
              this.coupon_off = data.coupon_off;
            }

            this.alertService.success(data.message, true);
          },
          error => {
            this.loading_promo = false;
            this.errors = error;
            this.alertService.error(error.get('message'));
          }
        )
    }

  }

  paySubmit() {

    if (this.type == 1) {
      this.form_submitted = true;
      this.loading = true;

      let tmp;
      //upgrade to premium
      if (this.premium) {
        //curently is pro
        if (this.pro) {
          tmp = '/user/subscription/switch-plan'
        } else
          tmp = '/user/subscribe-premium'
      } else {
        tmp = '/user/subscribe'
      }

      this.http.post(`${environment.apiUrl + tmp}`, {
        existing: this.existing,
        number: this.card_number,
        expiry: this.expiry_date,
        cvc: this.cvv,
        annually: this.annually,
        premium: this.premium,
        promo_code_id: this.promo_code_id
      })
        .subscribe(
          (data: any) => {


            this.loading = false;

            this.currentUser.pro = true;
            this.currentUser.stripe = true;
            this.currentUser.subscription_end = null;
            this.currentUser.unpaid = false;
            if (this.existing == 0) {
              this.currentUser.card_last4 = this.card_number.substr(this.card_number.length - 4);
            }

            localStorage.setItem('currentUser', JSON.stringify(this.currentUser));

            if (data.redirect_url) {
              window.location.href = data.redirect_url;
            }

            this.alertService.success(data.message, true);
            this.router.navigateByUrl('/profile');


          },
          error => {
            this.loading = false;
            this.errors = error;
            this.alertService.error(error.get('message'));
          }
        )
    } else if (this.type == 2) {
      this.form_submitted = true;
      this.loading = true;

      this.http.post(`${environment.apiUrl}/user/webinar-payment`, {
        existing: this.existing,
        id: this.id,
        number: this.card_number,
        expiry: this.expiry_date,
        cvc: this.cvv
      })
        .subscribe(
          (data: any) => {
            this.alertService.success(data.message, true);
            this.router.navigateByUrl('/webinars');
            this.loading = false;

            this.currentUser.stripe = true;
            if (this.existing == 0) {
              this.currentUser.card_last4 = this.card_number.substr(this.card_number.length - 4);
            }

            localStorage.setItem('currentUser', JSON.stringify(this.currentUser));
            if (data.redirect_url) {
              window.location.href = data.redirect_url;
            }


          },
          error => {
            this.loading = false;
            this.errors = error;
            console.log(this.errors);
            this.alertService.error(error.get('message'));
          }
        )
    } else if (this.type == 3) {
      this.form_submitted = true;
      this.loading = true;

      this.http.post(`${environment.apiUrl}/user/certificate-payment`, {
        existing: this.existing,
        id: this.id,
        number: this.card_number,
        expiry: this.expiry_date,
        cvc: this.cvv
      })
        .subscribe(
          (data: any) => {
            this.alertService.success(data.message, true);
            this.router.navigateByUrl('/certificates');
            this.loading = false;

            this.currentUser.stripe = true;
            if (this.existing == 0) {
              this.currentUser.card_last4 = this.card_number.substr(this.card_number.length - 4);
            }

            localStorage.setItem('currentUser', JSON.stringify(this.currentUser));
            if (data.redirect_url) {
              window.location.href = data.redirect_url;
            }
            window.open(data.url);
          },
          error => {
            this.loading = false;
            this.errors = error;
            console.log(this.errors);
            this.alertService.error(error.get('message'));
          }
        )

    } else if (this.type == 4) {
      this.form_submitted = true;
      this.loading = true;

      this.http.post(`${environment.apiUrl}/user/invoice-pay`, {
        existing: this.existing,
        id: this.id,
        number: this.card_number,
        expiry: this.expiry_date,
        cvc: this.cvv,
        promo_code_id: this.promo_code_id
      })
        .subscribe(
          (data: any) => {
            this.alertService.success(data.message, true);
            this.router.navigateByUrl('/invoices');
            this.loading = false;

            this.currentUser.stripe = true;
            if (this.existing == 0) {
              this.currentUser.card_last4 = this.card_number.substr(this.card_number.length - 4);
            }

            localStorage.setItem('currentUser', JSON.stringify(this.currentUser));
            if (data.redirect_url) {
              window.location.href = data.redirect_url;
            }
          },
          error => {
            this.loading = false;
            this.errors = error;
            console.log(this.errors);
            this.alertService.error(error.get('message'));
          }
        )
    }


  }

}
