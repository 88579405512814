import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { AuthenticationService } from '../_services';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    errors = new Map();
    constructor(private authenticationService: AuthenticationService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(catchError(err => {
            this.errors.clear();
            if (err.status === 401) {              
                localStorage.removeItem('currentUser');
                // auto logout if 401 response returned from api
                this.authenticationService.logout();
                //location.reload(true);
                this.errors.set('message',err.error.message)
            } else if("exception" in err.error) {
                this.errors.set('message',err.error.message);
                this.errors.set('exception', err.error.message);
            } else {
                const error = err.error.errors;
                this.errors.set('message',err.error.message);
                var type;
                for (let i = 0; i < error.length; i++) {
                    if(error[i].type == 'user.name') {
                        this.errors.set('name', error[i].message);
                    } else if(error[i].type == 'user.email') {
                        this.errors.set('email', error[i].message);
                    } else if(error[i].type == 'user.password') {
                        this.errors.set('password', error[i].message);
                    } else if(error[i].type == 'user.category_id') {
                        this.errors.set('category_id', error[i].message);
                    } else {
                        this.errors.set(error[i].type, error[i].message);
                    }
                }
            }
           
            return throwError(this.errors);
        }))
    }
}