import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { AlertService } from '../../_services';
import { environment } from '../../../environments/environment';
import saveAs from 'file-saver';

@Component({
  selector: 'app-certificates',
  templateUrl: './certificates.component.html',
  styleUrls: ['./certificates.component.scss']
})
export class CertificatesComponent implements OnInit {
   certificates;
   loading=false;
   ready=false;

    print_popup = false;
    title = "";
    price: "";
    paid: "";
    currentUser;
    pro;
    premium;
    id;

  constructor( private router: Router,
    private http: HttpClient,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private alertService: AlertService,) {
        this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    }

  ngOnInit() {
    this.http.get(`${environment.apiUrl}/video/certificates`)
            .subscribe(
                data => {
                    this.certificates = data; 
                    this.loading=true;   
                    this.ready=true;               
                },
                error => {
                   
                }
            );
        this.pro = this.currentUser.pro;
        this.premium = this.currentUser.premium;     
  }

  print(url){
    window.open(url);
  }

    print_show(id, title, price, paid) {
        this.print_popup = true;
        this.title = title;        
        this.price = price;
        this.paid = paid;
        this.id = id;
    }
    hide_popup() {
        this.print_popup = false;
        this.title = "";
        this.price = "";
        this.paid = "";
    }

    pay() {
        this.router.navigate(['/payment'],
        { queryParams:
            {
                type: 3,
                id: this.id,
                name: this.title,
                price: this.price
            } 
        });
    }

}
