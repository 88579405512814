﻿import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { AlertService } from '../_services';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
@Component({
    selector: 'alert',
    templateUrl: 'alert.component.html'
})

export class AlertComponent implements OnInit, OnDestroy {
    private subscription: Subscription;
    message: any;
    iframeSrc: SafeUrl;

    constructor(private alertService: AlertService,
        public sanitizer: DomSanitizer) { }

    ngOnInit() {
        this.subscription = this.alertService.getMessage().subscribe(message => { 
            this.message = message; 
            if(message){
            this.iframeSrc = this.sanitizer.bypassSecurityTrustResourceUrl(message.text);
            }
        });   
            
    }  
    clear(){
         this.alertService.clear();
    }
   post(id,){
       console.log(id);

   }


    ngOnDestroy() {
        this.subscription.unsubscribe();
    }
}