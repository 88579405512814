import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { FormBuilder, FormGroup, Validators, FormControl, NgForm } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { AlertService } from '../_services';

@Component({
  selector: 'app-survey',
  templateUrl: './survey.component.html',
  styleUrls: ['./survey.component.scss']
})
export class SurveyComponent implements OnInit {
  test_end = false;
  test_success = false;
  form: FormGroup;
  survey;
  id;
  questions = [];
  loaded = false;
  result;
  ans = new Map();
  certificate_url;
  counter = 0;
  correct_arr = [];
  correct;
  currentUser;
  validation_failed=false; 

  constructor(private http: HttpClient,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private alert: AlertService,
    private router: Router, ) { }

  ngOnInit() {

    this.http.get(`${environment.apiUrl}/survey`)
      .subscribe(
        data => {
          this.survey = data;
          this.survey.forEach(element => {
            this.questions.push({ 'id': element.id });
          });
          this.form = this.formBuilder.group(
            this.questions.reduce((acc, curr) => ({ ...acc, [curr.id]: '' }), {})
          );
          this.loaded = true;
      
        
        },
        error => {
         
        }
      );
  }

  onSubmit() {

    Object.keys(this.form.controls).forEach(key => {

      this.ans[key] = this.form.get(key).value;
    });
   
    this.http.post(`${environment.apiUrl}/survey/answers`, { questions: this.ans })
      .subscribe(
        data => {
          this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
          this.currentUser.survey = true;
          localStorage.setItem('currentUser', JSON.stringify(this.currentUser));
          this.alert.success('Thank you for completing our survey!', true);
          this.router.navigateByUrl('/profile');
          this.validation_failed=false;
        },
        error => {
        
            this.validation_failed=true;
          
        }
      );
  }
}
