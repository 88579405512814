import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { Router ,ActivatedRoute ,Params } from '@angular/router';
import { AlertService } from 'src/app/_services';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.scss']
})
export class EditComponent implements OnInit {

  constructor(private http: HttpClient, private route: ActivatedRoute, private router: Router, private alertService: AlertService) { }
  webinar_name;
  webinar_time;
  webinar_date;
  webinar_desc = "";
  form_submited = false;
  loading = false;
  errors = new Map();
  pipe = new DatePipe('en-US');
  id;
  today = this.pipe.transform(new Date(), 'MM/dd/yyyy');
  invalid_date="";
  question_id = 0;
  questions = [{ id: 0, value: "", correct: "", invalid: '', options: [{ id: 0, content: "", question_id: '' }, { id: 1, content: "", question_id: '' }] }];
  chars = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];
  api_questions = [];
  webinar_type;
  user_types;

  webinar_types;
  roles;
  v_roles = new Map();
  v_roles_text = "";
  api_roles = [];

  v_types = new Map();
  v_types_text = "";
  api_type = "";

  webinar_price;
  roles_names = [];

  
  role_id;
  type_id;

  ngOnInit() {
    this.http.get(`${environment.apiUrl}/webinar-types`)
      .subscribe(
        (data: any) => {
          this.webinar_types = data;
        })

    this.http.get(`${environment.apiUrl}/user-categories`)
      .subscribe(
        (data: any) => {
          this.roles = data;
        })

    this.route.params.subscribe((params: Params) => { this.id = params['id'] });
    this.http.get(`${environment.apiUrl}/admin/webinar/edit/` + this.id )
      .subscribe(
        (data: any) => {
          this.webinar_name=data.name;
          this.webinar_time=data.time;
          this.webinar_date=data.date;
          this.webinar_desc = data.description;
          this.webinar_price = data.price;

          if(data.webinar_type != 3) {
            (document.getElementById("webinarPrice") as any).disabled = true;
        }
        this.questions = [];
        let i = 0;
        let j = 0;
        let corect;

        data.questions.forEach(element => {
          j = 0;

          element.options.forEach(element2 => {
            if (element2.id == element.correct_option_id) {
              corect = j;
            }
            j++;
          });

          this.questions.push({
            id: i++,
            value: element.content,
            correct: corect,
            invalid: '',
            options: element.options
          });
        });
        this.question_id = this.questions.length;

          
          this.webinar_types.forEach(element => {
                  if (element.id == data.webinar_type) {
                    this.type_id = element.id;
                    this.api_type=element.id;
                    this.v_types_text=element.name;
                  }
                });

           for (let key in data.user_types) {
                  this.v_roles_text += data.user_types[key] + ", ";
                  this.api_roles.push(key);
                  this.roles_names.push(data.user_types[key]);
                  this.v_roles.set(data.user_types[key], true);
                }

        })

        
  }


      RoleChange(values: any, type, id) {
        this.v_roles.set(type, values.currentTarget.checked)

        if (this.api_roles.includes(id.toString())) {
          this.api_roles.splice(this.api_roles.indexOf(id.toString()), 1);
        } else {
          this.api_roles.push(id.toString());
        }

        this.v_roles_text = "";
        this.v_roles.forEach((value: boolean, key: string) => {

          if (value) {
            this.v_roles_text += key + ", ";
          }
        });

      }


      TypeChange(values: any, type, id) {
        this.api_type = id;
        this.type_id=id;
        console.log( this.api_type);
        this.v_types_text = type;

        if(id == 3) {
            (document.getElementById("webinarPrice") as any).disabled = false;
        } else {
            (document.getElementById("webinarPrice") as any).disabled = true;
        }
      }


  submit() {

    if(this.webinar_date) {
        if (this.validateDate()) {
          return;
        }
    }
    this.questions_prepare();

    console.log(this.webinar_price);
    this.form_submited = true;
    this.loading = true;
    this.http.post(`${environment.apiUrl}/admin/webinar/store/`+this.id, {
      name: this.webinar_name,
      description: this.webinar_desc,
      date: (this.webinar_date) ? this.pipe.transform(this.webinar_date, 'MM/dd/yyyy') : null,
      time: this.webinar_time,
      user_types: this.api_roles,
      webinar_type: this.type_id,
      price: this.webinar_price,
      questions: this.api_questions

    })
      .subscribe(
        (data: any) => {
          this.alertService.success(data.message, true);
          this.router.navigateByUrl('/admin/webinars');
          this.loading = false;
        },
        error => {
          this.loading = false;
          this.errors = error;
          this.alertService.error(error.get('message'));
        }
      )
  }

  questions_prepare() {

    this.questions.forEach(element => {
      if (element.value) {
        let opt = [];
        element.options.forEach(element => {
          if (element.content != "") {
            opt.push(element.content);
          }
        });
        this.api_questions.push({
          content: element.value,
          correct: element.correct,
          options: opt
        });
      }
    });

  }

  corect(q_id, a_id) {
    this.questions[q_id].correct = a_id;
  }

  validate_form() {
    let status = false;

   

    this.questions.forEach(element => {
      element.invalid = '';
      if (element.value != "") {
        if (element.correct === "" || element.correct == "/" || !element.options[element.correct]) {
          element.invalid = 'Please choose correct answer';
          status = true
        }
        if (element.options[0].content == "" || element.options[1].content == "") {
          element.invalid = 'Please add at least two options';
          status = true;
        }
      }
    });
    return status;
  }
  addQuestion() {
    this.question_id++;
    this.questions.push({ id: this.question_id, value: "", correct: "/", invalid: '', options: [{ id: 0, content: "", question_id: '' }, { id: 1, content: "", question_id: '' }] });
  }
  addAnswers(id) {
    const new_id = this.questions[id].options[this.questions[id].options.length - 1].id;
    this.questions[id].options.push({ id: new_id + 1, content: "", question_id: '' });
  }

  removeAnswers(q) {
    this.questions[q].options.pop();
  }
  removeQuestion(i){
    this.questions.splice(i, 1);
  }


  validateDate() {
    let date1 = new Date(this.webinar_date);
    let date2 = new Date(this.today);
    if (isNaN(Date.parse(this.webinar_date))) {
      this.invalid_date = "Invalid date"
      console.log("yes");
      return true
    }

    if (date1 < date2) {
      this.invalid_date = "You can not create a webinar on a date that has already passed."
      console.log("yes");
      return true
    }
    else {

      console.log(this.webinar_date, this.today);
      this.invalid_date = "";
      return false
    }
  }

}
